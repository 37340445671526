import Cookies from 'js-cookie';

const token = Cookies.get('token');

export const navData = [
  {
    label: "SEARCH",
    // url: "home/search",
    subNav: [
      {
        label: "View",
        url: ``,
        onClick: (setState) => {
          setState(true);
        },
      },
    ],
  },
  {
    label: "SUBJECTS",
    url: "home/subjects",
    subNav: [
      {
        label: "View",
        url: ``,
        onClick: (setState) => {
          setState(true);
        },
      },
    ],
  },
  {
    label: "CAIE",
    url: "home/caie",
    navData:'1',
    subNav: [
      {
        label: "View",
        url: ``,
        onClick: (setState) => {
          setState(true);
        },
      },
    ],
  },
  {
    label: "ED-EXCEL",
    url: "home/ed-excel",
    navData:'2',
    subNav: [
      {
        label: "View",
        url: ``,
        onClick: (setState) => {
          setState(true);
        },
      },
    ],
  },
  {
    label: "IB-MYP-DP",
    url: "home/ib-myp-dp",
    navData:'32',
    subNav: [
      {
        label: "View",
        url: ``,
        onClick: (setState) => {
          setState(true);
        },
      },
    ],
  },
  // {
  //   label: "AQA",
  //   url: "home/aqa",
  //   navData:'4',
  //   subNav: [
  //     {
  //       label: "View",
  //       url: ``,
  //       onClick: (setState) => {
  //         setState(true);
  //       },
  //     },
  //   ],
  // },
  // {
  //   label: "OCR",
  //   url: "home/ocr",
  //   navData:'5',
  //   subNav: [
  //     {
  //       label: "View",
  //       url: ``,
  //       onClick: (setState) => {
  //         setState(true);
  //       },
  //     },
  //   ],
  // },
  // {
  //   label: "AP",
  //   url: "home/ap",
  //   navData:'6',
  //   subNav: [
  //     {
  //       label: "View",
  //       url: ``,
  //       onClick: (setState) => {
  //         setState(true);
  //       },
  //     },
  //   ],
  // },
  {
    label: "STUDENTS SUPPORT",
    // url: token ? "home/studentsSupport" : '/login',
    subNav: [
      {
        label: "View",
        url: ``,
        onClick: (setState) => {
          setState(true);
        },
      },
    ],
  },
  {
    label: "TEACHER SUPPORT",
    url: '/teacherProfile',
    subNav: [
      {
        label: "View",
        url: ``,
        onClick: (setState) => {
          setState(true);
        },
      },
    ],
  },
  {
    label: "ABOUT",
    url: "home/about",
    subNav: [
      {
        label: "View",
        url: ``,
        onClick: (setState) => {
          setState(true);
        },
      },
    ],
  }
];
export const nav = {
  presentation: navData[0],
};
