// Login.js

import React, { useState } from "react";

// MUI Components
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { TextField, Typography, InputAdornment, Box } from "@mui/material";

// Third Party
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";

// Custom Components
import GradientButton from "../../component/gradientButton";
import ModalWithCheckboxes from "../../component/modal/ProgramModla";

//Icons
import MailIcon from "@mui/icons-material/Mail";
import LockIcon from "@mui/icons-material/Lock";
import IconButton from "@mui/material/Button";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// Api
import httpService from "../../service/http.service";
import { api } from "../../interfaces/api.interface";

// Css
import "./Login.scss";
import CourseSelection from "../courseSelection/CourseSelection";

const Login = () => {
  // states
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState({ iSError: false, message: "" });
  const [visible, setVisible] = useState(false);
  const [teacherModal, setTeacherModal] = useState(false);

  const navigate = useNavigate();
  const toggleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const SetToken = (token) => {
    Cookies.set("token", token);
  };

  const clearFields = () => {
    setEmail("");
    setPassword("");
    setApiError({ iSError: false, message: "" });
  };

  const handleSignIn = () => {
    const data = {
      email: email,
      password: password,
    };

    console.log(data);
    setIsLoading(true);

    httpService
      .call(api.loginUser(), data)
      .then((res) => {
        console.log("Login API ==>", res?.data);

        const { succeeded, messages, data } = res?.data;

        if (succeeded) {
          const { token } = data;
          console.log("Token ==>", token);
          SetToken(token);
          setIsLoading(false);
          getRoles();
        } else if (!succeeded) {
          const { $values } = messages || {};
          setApiError({ iSError: true, message: $values[0] });
          setIsLoading(false);
        } else {
          throw new Error(res.error);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  const getRoles = () => {
    httpService
      .call(api.getRoles(), null, true)
      .then((res) => {
        console.log("Role API ==>", res.data);
        const { succeeded, messages } = res?.data;

        if (succeeded) {
          clearFields();
          if (messages?.$values[0] == "Teacher") {
            localStorage.setItem("role", "Teacher");
            getTeacherProfile();
          } else {
            localStorage.setItem("role", "Student");
            isFirstLogin();
          }
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  const isFirstLogin = () => {
    httpService
      .call(api.isFirstLogIn(), null, true)
      .then((res) => {
        console.log("First Login API ==>", res.data);
        const { succeeded, messages, data } = res?.data;

        if (data) {
          navigate("/studentDashboard");
        } else {
          setVisible(true);
          clearFields();
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  const getTeacherProfile = () => {
    httpService
      .call(api.getTeacherProfile(), true)
      .then((res) => {
        console.log("Teacher Profile ==>", res.data);
        const { succeeded, data } = res?.data;
        if (succeeded) {
          if (data?.title) {
            navigate("/teacherDashboard");
          } else {
            navigate("/profileSetup", { state: data });
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateData();
  };

  const validateData = () => {
    const newErrors = {};

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is invalid";
    }
    if (!password) {
      newErrors.password = "Password is required";
    } else if (password.length < 8) {
      newErrors.password = "Password must be at least of length 8";
    } else if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/.test(password)) {
      newErrors.password =
        "Your password must contain at least one uppercase letter, one lowercase letter, and one number.";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      handleSignIn();
    }
  };

  return (
    <Box style={{ width: "100%" }}>
      <Box className="container">
        <CourseSelection
          open={visible}
          // navigationHandler={() => navigate("/studentDashboard")}
        />
        <ModalWithCheckboxes
          open={teacherModal}
          isTeacher={true}
          navigationHandler={() => navigate("/teacherDashboard")}
        />
        <div className="root">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {apiError.iSError && (
            <Alert variant="outlined" severity="error">
              {apiError.message}
            </Alert>
          )}

          <form className={"form"} onSubmit={handleSubmit}>
            <Typography variant="h1" style={{ fontSize: 48, marginBottom: 20 }}>
              Sign In
            </Typography>

            <TextField
              placeholder="E-mail"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon sx={{ color: "#03494B" }} />
                  </InputAdornment>
                ),
              }}
              fullWidth
              margin="normal"
              error={!!errors.email}
              helperText={errors.email}
            />

            <TextField
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: "#03494B" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff sx={{ color: "grey" }} />
                      ) : (
                        <Visibility sx={{ color: "grey" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              margin="normal"
              error={!!errors.password}
              helperText={errors.password}
            />

            <GradientButton onClick={handleSubmit}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {" "}
                Sign In
              </Typography>
            </GradientButton>
          </form>

          <Typography variant="span" style={{ color: "#B9B9B9" }}>
            Create new account?{" "}
            <Link to={"/Signup"}>
              <Typography
                variant="span"
                color={"#03494B"}
                style={{ fontWeight: "bold" }}
              >
                {" "}
                Sign Up
              </Typography>
            </Link>
          </Typography>
        </div>
      </Box>
    </Box>
  );
};

export default Login;
