import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import DownloadImg from '../../assets/download.png'
import RectangleImg from '../../assets/Rectangle.png'
import pdfImg from '../../assets/pdf.png';
import VideoIcon from '../../assets/video-icon.png'
import './table.scss'
import CustomizedCheckbox from '../customCheckbox';
import { pdfDownload } from '../../utils/Utils';
import PlayCircle from '@mui/icons-material/PlayCircle';
import { useNavigate } from 'react-router-dom';
const NotesTable = (props) => {
    const { papers, handleSelectedPdf, multiPdfDownload, selectedPDF,isVideo } = props

    const navigate = useNavigate();

    console.log("papers==>",papers);
    const CustomHeader = ({isVideo}) => (
        <Grid container className='header' style={{ background: '#E7E7E7' }} >
            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <img src={RectangleImg} className='rectangle' />
            </Grid>
            <Grid item xs={2} lg={1} style={{ display: 'flex', alignItems: 'center', }}>
                <Typography variant='body2' >Doc Type</Typography>
            </Grid>
            <Grid item xs={5} lg={7} style={{ display: 'flex', alignItems:'flex-start',flexDirection:'column',justifyContent:'center'   }}>
                <Typography variant='body2'>Document Name</Typography>
            </Grid>
            <Grid item xs={3} lg={2} style={{ display: 'flex', alignItems: 'center' }}>
                {/* <Typography variant='body2'>Paper Type</Typography> */}
            </Grid>
            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }} onClick={multiPdfDownload}>
               {isVideo ?   <PlayCircle className='download' style={{width:30, height:30}} /> :<img src={DownloadImg} className='download' />}  </Grid>
        </Grid>
    )

    const NotesRow = (props) => {
        const { item } = props
        return (
            <>
                { item.notesName && <Grid container className='row' key={item.id}>
                    <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CustomizedCheckbox isSelected={selectedPDF.includes(item.notesPdf || item.notesUrl)} handleSelectedPdf={() => handleSelectedPdf(item.notesPdf)} />
                    </Grid>
                    <Grid item xs={2} lg={1} style={{ display: 'flex', justifyContent: 'start' }}>
                        <img src={pdfImg} className='pdfImg' />
                    </Grid>
                    <Grid item xs={5} lg={7} style={{ display: 'flex', flexDirection: 'column',alignItems:'flex-start'}}>
                        <Typography variant='h3'>{item.notesName}</Typography>
                        {/* <Typography variant='body1'>Uploaded 3 Days Ago</Typography> */}
                    </Grid>
                    <Grid item xs={3} lg={2} style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <Typography variant='body1'>{item.notesName}</Typography> */}
                    </Grid>
                    <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }} onClick={pdfDownload.bind(this, item.notesName, item.notesPdf)}>
                        <img src={DownloadImg} className='download' />
                    </Grid>
                </Grid>
                }
            </>
        )
    }


    const VideosRow = (props) => {
        const { item } = props;
        return (
            <>
                {item.videoName && <Grid container className='row' key={item.id}>
                    <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {/* <CustomizedCheckbox isSelected={selectedPDF.includes(item.notesPdf)} handleSelectedPdf={() => handleSelectedPdf(item.notesPdf)} /> */}
                    </Grid>
                    <Grid item xs={2} lg={1} style={{ display: 'flex', justifyContent: 'start' }}>
                        <img src={VideoIcon} className='pdfImg' />
                    </Grid>
                    <Grid item xs={5} lg={7} style={{ display: 'flex', flexDirection: 'column', alignItems:"flex-start" }}>
                        <Typography variant='h3'>{item.videoName}</Typography>
                        {/* <Typography variant='body1'>Uploaded 3 Days Ago</Typography> */}
                    </Grid>
                    <Grid item xs={3} lg={2} style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <Typography variant='body1'>{item.notesName}</Typography> */}
                    </Grid>
                    <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }} component={'a'} href={item.videoUrl} >
                        <PlayCircle className='download' style={{width:30, height:30}}/>
                    </Grid>
                </Grid>
                }
            </>
        )
    }


    return (
        <Grid container className="mainLayout">
            <CustomHeader isVideo={isVideo} /> 
            <Box style={{ background: '#F5F5F5', width: '100%' }}>
                {
                    papers?.map((e, i) => (
                        
                        <>
                            {[e.notesPdf || e.notesUrl] && <NotesRow item={e} />}
                            {e.videoName && <VideosRow item={e} />}
                        </>
                    ))
                }
            </Box>
        </Grid>
    );
};

export default NotesTable;