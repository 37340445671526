import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import CustomButton from "../button";
import { IoMdArrowRoundForward,IoMdArrowRoundBack } from "react-icons/io";
import './pagination.scss'

const Pagination = (props) => {
    const { page, count, onChange } = props
    let totalpage = Math.ceil(count / 10)
    return (
        <Grid className="paginationLayout">
            <CustomButton disabled={page===1} onClick={onChange.bind(this,page>1?page-1:page)} text="PREVIOUS" color="green" className={'directonButton'} icon={<IoMdArrowRoundBack color="#C0C0C0" />} />
            <CustomButton disabled={page===totalpage} onClick={onChange.bind(this,page<totalpage?page+1:page)} text="NEXT" color="green" className={'directonButton'} icon={<IoMdArrowRoundForward color="#C0C0C0" />} />
            <Box className="rightItem">
                <Typography variant="h3">Page <span>{page.length <= 1 ? `0${page}` : page}</span> of {totalpage}</Typography>
            </Box>
        </Grid>
    )
};

export default Pagination;
