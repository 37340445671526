import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Box,
  Grid,
  Avatar,
  Paper,
  CircularProgress,
} from "@mui/material";

// Icons
import BadgeIcon from "@mui/icons-material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

// Api
import httpService from "../../service/http.service";
import { api } from "../../interfaces/api.interface";


const StudentProfileView = ({ navigation }) => {

  // States
  const [profile, setProfile] = useState(null);
  const [avatarImage, setAvatarImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState({ isError: false, message: "" });
  
  // Ref for file input
  const fileInputRef = useRef(null);

  const uploadProfilePicture = (image) => {
    setIsLoading(true);
    httpService
      .call(api.uploadProfilePicture(), image, true)
      .then((res) => {
        console.log("Profile Upload Response ==>", res.data);
        const { succeeded, message, data } = res?.data;

        if (succeeded) {
            getProfile()
        } else {
          setApiError({ isError: true, message });
        }
        setIsLoading(false)
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
        setApiError({
          isError: true,
          message: "An error occurred while uploading the profile.",
        });
      });
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";

      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load something...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleFileInputChange = (e) => {
    let file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        const imageFile = {
          data: result,
          name: file?.name,
          extension: "." + file?.type?.split("/")?.pop(),
        };
        console.log("File Is", imageFile);
        uploadProfilePicture(imageFile);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProfile = () => {
    httpService
      .call(api.getUserProfile(), true)
      .then((res) => {
        console.log("User Profile =>", res?.data?.data);
        const { succeeded, data } = res?.data;

        if (succeeded) {
          setProfile(data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    console.log("test");
    getProfile();
  }, []);

  return (
    <Box style={{ width: "100%" }}>
      <Box className={"container"}>
        <div className="root-div" style={{ width: "60%" }}>
          <Box>
            <PhotoCameraIcon
              sx={{ fontSize: 24, color: "#03494B", marginRight: 10, position: 'absolute', zIndex: 100,cursor: "pointer"
 }}
              onClick={() => fileInputRef.current.click()}
            />
            <input 
            type="file" 
            accept="image/*"
              ref={fileInputRef} 
              hidden 
              onChange={handleFileInputChange} 
            />
           {!isLoading ? (
              <Avatar
                src={profile?.profilePictureDataUrl || avatarImage}
                alt="Profile Image"
                sx={{
                  width: 100,
                  height: 100,
                  margin: "auto",
                  cursor: "pointer",
                  marginBottom: 5,
                  borderWidth: 2,
                  borderColor: '#03494B',
                  borderStyle: 'solid'
                }}
              />
            ) : (
              <Box sx={{
                width: 100,
                height: 100,
                backgroundColor: 'grey',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                borderRadius: '50%',
                marginBottom: 5,
              }}>
                <CircularProgress />
              </Box>
            )}
          </Box>
          <Grid container spacing={3} style={{ width: "80%", margin: "auto" }}>
            <Grid item xs={12} sm={6}>
              <Paper
                elevation={3}
                className="info-paper"
                style={{ padding: 12 }}
              >
                <BadgeIcon
                  sx={{ fontSize: 24, color: "#03494B", marginRight: 10 }}
                />
                <Typography variant="subtitle1">First Name</Typography>
                <Typography variant="body1">
                  {profile?.firstName}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper
                elevation={3}
                className="info-paper"
                style={{ padding: 12 }}
              >
                <BadgeIcon
                  sx={{ fontSize: 24, color: "#03494B", marginRight: 10 }}
                />
                <Typography variant="subtitle1">Last Name</Typography>
                <Typography variant="body1">
                  {profile?.lastName}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper
                elevation={3}
                className="info-paper"
                style={{ padding: 12 }}
              >
                <MailIcon
                  sx={{ fontSize: 24, color: "#03494B", marginRight: 10 }}
                />
                <Typography variant="subtitle1">Email</Typography>
                <Typography variant="body1">{profile?.email}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper
                elevation={3}
                className="info-paper"
                style={{ padding: 12 }}
              >
                <PhoneIcon
                  sx={{ fontSize: 24, color: "#03494B", marginRight: 10 }}
                />
                <Typography variant="subtitle1">Phone Number</Typography>
                <Typography variant="body1">{profile?.phoneNumber}</Typography>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Box>
    </Box>
  );
};

export default StudentProfileView;
