import React, { Navigate, Route } from "react-router-dom";
// import PageContent from "../layouts/PageContent";
// import PaperUpload from "../screens/PaperUpload";
// import RecordList from "../screens/RecordsLists";
// import NotFound from "../layouts/NotFound";
// import MyAccount from "../screens/MyAccount";

// import Login from "../screens/login/Login";
// import Signup from "../screens/signup/signup";
import TeacherDashboard from "../screens/dashoard/teacherDashboard";
import TeacherProfile from "../screens/teachersProfile/teacherProfile";
import StudentDashboard from "../screens/student/dashboard/StudentDashboard";
import StudentMaterial from "../screens/StudentMaterial/StudentMaterial";
import ChangePassword from "../screens/profileMenu/changePassword";
import ProfileView from "../screens/profileMenu/viewProfile";
import StudentProfileView from "../screens/profileMenu/studentViewProfile";
import Exam from "../screens/student/Exam";
import CourseSelection from "../screens/courseSelection/CourseSelection";
export const privateRoutes = [
    { index: true, element: <Navigate to="dashboard" /> },
      {
        path: "teacherdashboard",
        element: <TeacherDashboard />,
      },
      {
        path: "studentdashboard",
        element: <StudentDashboard />,
      },
      {
        path: "StudentMaterial/:id",
        element: <StudentMaterial />,
      },
      {
        path: "ChangePassword",
        element: <ChangePassword />,
      },
      {
        path: "ProfileView",
        element: <ProfileView />,
      },
      {
        path: "StudentProfileView",
        element: <StudentProfileView />,
      },
      {
        path: "courseSelection",
        element: <CourseSelection />,
      },
    // {
    //     path: "PaperUpload",
    //     element: <PageContent />,
    //     children: [
    //         { index: true, element: <Navigate to="RecordList" /> },
    //         {
    //             path: 'Recordlist',
    //             element: <RecordList />
    //         },
    //         {
    //             path: 'CreateForm',
    //             element: <PaperUpload />
    //         },
    //     ]
    // },
    // {
    //     path: "profile/my-account",
    //     element: <MyAccount />,
    // },
    // { path: "*", element: <NotFound /> },
];