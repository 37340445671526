import React, { useState, useEffect } from "react";

// MUI Components
import {
  TextField,
  Button,
  Typography,
  InputAdornment,
  Box,
  Alert,
  FormControl,
  Grid,
  Backdrop,
  CircularProgress,
} from "@mui/material";

// Icons
import BadgeIcon from "@mui/icons-material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import "./ProfileSetup.scss";

// Custom Components
import GradientButton from "../../component/gradientButton";

// Third Party
import { useParams, useNavigate, useLocation } from "react-router-dom";

// Api
import httpService from "../../service/http.service";
import { api } from "../../interfaces/api.interface";

const ProfileSetup = () => {
  const [formValues, setFormValues] = useState({
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    shortIntro: "",
    subjects: "",
    examSystem: "",
    levelsName: "",
    qualification: "",
    // professionalQualification: "",
    expIntitution: "",
    expYears: "",
  });

  const [profileImage, setProfileImage] = useState(null);
  const [base64, setBase64] = useState(null);

  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState({ isError: false, message: "" });
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  let location = useLocation();

  let data = location.state;
  console.log("Params ==>", data);

  useEffect(() => {
    if (data) {
      setFormValues(data);
    }
    console.log(data);
  }, [data]);

  const uploadTeacherProfile = (profileData) => {
    setIsLoading(true);
    httpService
      .call(api.uploadTeacherProfile(), profileData, true)
      .then((res) => {
        console.log("Profile Upload Response =====>", res.data);
        const { succeeded, message } = res?.data;

        if (succeeded) {
          alert("Profile updated successfully!");
          navigate("/teacherDashboard");
        }

        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const uploadProfilePicture = (image) => {
    httpService
      .call(api.uploadProfilePicture(), image, true)
      .then((res) => {
        console.log("Profile Upload Response ==>", res.data);
        const { succeeded, message, data } = res?.data;

        if (succeeded) {
          alert("picture uploaded successfully");
          console.log(data);
        } else {
          setApiError({ isError: true, message });
        }
      })
      .catch((e) => {
        console.log(e);
        setApiError({
          isError: true,
          message: "An error occurred while uploading the profile.",
        });
      });
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";

      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...

      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };


  const handleFileInputChange = (e) => {
    let file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        const imageFile = {
          data: result,
          name: file?.name,
          extension: file?.type,
        };
        console.log("File Is", imageFile);
        setBase64(imageFile);
        uploadProfilePicture(imageFile);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateData()) {
      const profileData = {
        subjects: formValues.subjects,
        examSystem: formValues.examSystem,
        levelsName: formValues.levelsName,
        qualification: formValues.qualification,
        shortIntro: formValues.shortIntro,
        expIntitution: formValues.expIntitution,
        expYears: formValues.expYears,
        title: formValues.title,
      };
      uploadTeacherProfile(profileData);
    }
  };

  const validateData = () => {
    const newErrors = {};

    if (!formValues.title) {
      newErrors.title = "title is required";
    }
    if (!formValues.firstName) {
      newErrors.firstName = "First Name is required";
    }
    if (!formValues.lastName) {
      newErrors.lastName = "Last Name is required";
    }
    if (!formValues.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formValues.phoneNo) {
      newErrors.phoneNo = "phoneNo number is required";
    } else if (formValues.phoneNo.length < 11) {
      newErrors.phoneNo = "phoneNo number must have 11 digits";
    }
    if (!formValues.shortIntro) {
      newErrors.shortIntro = "Short Intro is required";
    } else if (formValues.shortIntro.length > 160) {
      newErrors.shortIntro = "Short Intro must be 160 words max";
    }
    if (!formValues.subjects) {
      newErrors.subjects = "subjects are required";
    }
    if (!formValues.examSystem) {
      newErrors.examSystem = "Examination System is required";
    }
    if (!formValues.levelsName) {
      newErrors.levelsName = "level is required";
    }
    if (!formValues.expIntitution) {
      newErrors.expIntitution = "Intitution is required";
    }
    if (!formValues.expYears) {
      newErrors.expYears = "Years served is required";
    }

    if (!formValues.qualification) {
      newErrors.qualification = "Qualification is required";
    }

    if (!base64) {
      alert("profile image is required");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <Box style={{ width: "100%" }}>
      <Box className={"container"}>
        <div
          className="root-div"
          style={{
            width: "60%",
          }}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {apiError.isError && (
            <Alert variant="outlined" severity="error">
              {apiError.message}
            </Alert>
          )}

          <Typography variant="h1" style={{ fontSize: 48, marginBottom: 20 }}>
            Profile Setup
          </Typography>

          <Button
            component="label"
            style={{
              backgroundColor: "#ccc",
              width: 100,
              height: 100,
              borderWidth: 1,
              borderRadius: 50,
            }}
          >
            <input type="file" hidden onChange={handleFileInputChange} />
            {!base64 ? (
              <AddAPhotoIcon sx={{ width: 50, height: 50, color: "#03494B" }} />
            ) : (
              <Box
                component={"img"}
                src={base64?.data}
                alt="Profile"
                sx={{
                  width: 100,
                  height: 100,
                  borderRadius: 50,
                  objectFit: "cover",
                  border: "1px solid grey",
                }}
              />
            )}
          </Button>

          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              style={{
                width: "80%",
              }}
            >
              <Grid item xs={12} lg={6}>
                <TextField
                  label="title"
                  name="title"
                  variant="outlined"
                  value={formValues.title}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  error={!!errors.title}
                  helperText={errors.title}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label="First Name"
                  name="firstName"
                  variant="outlined"
                  disabled
                  value={formValues.firstName}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BadgeIcon sx={{ color: "#03494B" }} />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  margin="normal"
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  label="Last Name"
                  name="lastName"
                  variant="outlined"
                  disabled
                  value={formValues.lastName}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BadgeIcon sx={{ color: "#03494B" }} />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  margin="normal"
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label="Email"
                  name="email"
                  variant="outlined"
                  disabled
                  value={formValues.email}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailIcon sx={{ color: "#03494B" }} />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  margin="normal"
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label="phoneNo Number"
                  name="phoneNo"
                  variant="outlined"
                  disabled
                  value={formValues.phoneNo}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIcon sx={{ color: "#03494B" }} />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  margin="normal"
                  error={!!errors.phoneNo}
                  helperText={errors.phoneNo}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label="Short Intro"
                  name="shortIntro"
                  variant="outlined"
                  rows={4}
                  value={formValues.shortIntro}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  error={!!errors.shortIntro}
                  helperText={errors.shortIntro}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  label="Examination System"
                  name="examSystem"
                  variant="outlined"
                  value={formValues.examSystem}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  error={!!errors.examSystem}
                  helperText={errors.examSystem}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  label="level"
                  name="levelsName"
                  variant="outlined"
                  value={formValues.levelsName}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  error={!!errors.levelsName}
                  helperText={errors.levelsName}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label="Qualification"
                  name="qualification"
                  variant="outlined"
                  value={formValues.qualification}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  error={!!errors.qualification}
                  helperText={errors.qualification}
                />
              </Grid>
              {/* <Grid item xs={12} lg={6}>
                <TextField
                  label="Professional Qualification"
                  name="professionalQualification"
                  variant="outlined"
                  value={formValues.professionalQualification}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
              </Grid> */}
              <Grid item xs={12} lg={6}>
                <TextField
                  label="Institution"
                  name="expIntitution"
                  variant="outlined"
                  value={formValues.expIntitution}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  error={!!errors.expIntitution}
                  helperText={errors.expIntitution}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label="Years Served"
                  name="expYears"
                  type="number"
                  variant="outlined"
                  value={formValues.expYears}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  error={!!errors.expYears}
                  helperText={errors.expYears}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                  <TextField
                    label="subjects"
                    name="subjects"
                    variant="outlined"
                    value={formValues.subjects}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={!!errors.subjects}
                    helperText={errors.subjects}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={12}>
                <GradientButton onClick={handleSubmit}>
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    {" "}
                    Submit
                  </Typography>
                </GradientButton>
              </Grid>
            </Grid>
          </form>
        </div>
      </Box>
    </Box>
  );
};

export default ProfileSetup;
