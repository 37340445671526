import React, { useState } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import './container.scss'

const Container = (props) => {
    const { children } = props
    return (
        <Box className={`centerContainer`}>
            {children}
        </Box>
    );
};


export default Container;
