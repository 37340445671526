import React from 'react'
import { Box, Grid, Typography } from '@mui/material';
import './about.scss';
import OnlineCourses from '../../assets/onlineCourses.png'
import LearnSomething from '../../assets/learn.png'
import { Padding } from '@mui/icons-material';


export default function About() {
  return (
    <Box style={{ width: '100%' }}>
      <Box className='container'>

        <Box className='text-Container'>
          <Typography variant='h1' sx={{ fontSize:{lg:42,sm:36,xs:30}}} style={{ color: '#fff', marginBottom: 18 }} > Your All-in-One Platform for Exam Success</Typography>
          <Typography variant='body1' style={{ color: '#fff' }}> SER is your one-stop shop for conquering CAIE, International Baccalaureate (IB), EDExcel, and AQA exams. We're a passionate team on a mission to empower students worldwide with the tools and resources they need to thrive.</Typography>
        </Box>


        <Box className='secondContainer' style={{ backgroundColor: '#FAFAFA',padding:24 }}>
          <Box className='innerContainer'>
            <Typography variant="h2" style={{ textAlign:"center", alignSelf: 'center', fontSize: '36px', margin: 32, color: '#03494B' }}>Why Choose SRE? </Typography>
            <Box>

          <Box style={{width:'80%',margin:'50px auto' }}>
            <Typography variant="h2" style={{ alignSelf: 'left', fontSize: '24px', color: '#03494B' }}>Free, Comprehensive Resources:  </Typography>
                <Typography  variant="body1" color={'black'} sx={{ fontSize:{lg:16,sm:18,xs:16}}}> We believe education should be accessible to all. That's why we offer a treasure trove of free resources, including:</Typography> 
               
                <ul>
                  <li> <Typography  variant="body2" color={'black'} sx={{ fontSize:{lg:14,sm:18,xs:16},margin:1}}>Past Papers: Perfect your exam technique with authentic past papers, getting a feel for question styles and time management. </Typography></li>
                  <li> <Typography  variant="body2" color={'black'} sx={{ fontSize:{lg:14,sm:18,xs:16},margin:1}}>Live MCQ Solver: Get instant solutions and explanations to multiple-choice questions, solidifying your understanding of key concepts.</Typography></li>
                  <li> <Typography  variant="body2" color={'black'} sx={{ fontSize:{lg:14,sm:18,xs:16},margin:1}}>Topical Questions: Dive deep into specific topics with focused practice questions that target your learning needs.</Typography></li>
                  <li> <Typography  variant="body2" color={'black'} sx={{ fontSize:{lg:14,sm:18,xs:16},margin:1}}>Examination Resources: Navigate exams with confidence by accessing essential resources like examiner reports and mark schemes. </Typography></li>
                  <li> <Typography  variant="body2" color={'black'} sx={{ fontSize:{lg:14,sm:18,xs:16},margin:1}}>Notes and Videos: Supplement your learning with clear and concise study notes and engaging video tutorials.</Typography></li>
                </ul>

                <Typography variant="h2" style={{ alignSelf: 'left', fontSize: '24px', color: '#03494B' }}>Track Your Progress:</Typography>
                <Typography  variant="body2" color={'black'} sx={{ fontSize:{lg:16,sm:16,xs:14,marginBottom:12}}}> Stay organized and motivated by tracking your studies on SER. Monitor your progress, identify areas for improvement, and celebrate your achievements!</Typography>
                
                <Typography variant="h2" style={{ alignSelf: 'left', fontSize: '24px', color: '#03494B' }}>Teacher Resources: </Typography>
                <Typography  variant="h3" color={'black'} sx={{ fontSize:{lg:16,sm:16,xs:14,marginBottom:12}}}>Educators play a vital role in student success. That's why we offer a platform for teachers to share their own high-quality resources with students, fostering collaboration and knowledge exchange within the learning community.</Typography>
     
                <Typography variant="h2" style={{ alignSelf: 'left', fontSize: '24px', color: '#03494B' }}>Vibrant Community:  </Typography>
                <Typography  variant="body1" color={'black'} sx={{ fontSize:{lg:16,sm:16,xs:14,marginBottom:12}}}>Connect with a worldwide network of learners, share study tips, and motivate each other on your academic journey.</Typography>
  
                </Box>
            </Box>

          </Box>
        </Box>

        
        <Box className='secondContainer' style={{ backgroundColor: 'white',padding:'50px 0px' }}>
          <Box className='innerContainer' style={{ textAlign: 'center' }}>

            <Grid container className='gridContainer' >
              <Grid item xs={7} lg={6} >
                <img src={OnlineCourses} />
              </Grid>
              <Grid item xs={5} lg={6} className="nestedContainer" style={{ textAlign: 'left'}}>
                <Typography variant="h2" style={{ color: 'red', fontSize: '36px', marginTop: 8, marginBottom: 8, color: '#03494B' }}>Our Mission</Typography>
                <Typography variant="h6" style={{ color: '#03494B' }}>At SER, we're committed to:</Typography>
                <ul className='list'>
                  <li><Typography  variant="body1" color={'black'} sx={{ fontSize:{lg:16,sm:16,xs:14,marginBottom:12}}}>Democratizing education by providing free and high-quality resources.</Typography></li>
                  <li><Typography  variant="body1" color={'black'} sx={{ fontSize:{lg:16,sm:16,xs:14,marginBottom:12}}}>Empowering students to achieve their full potential by fostering a love of learning and deep understanding.</Typography></li>
                  <li><Typography  variant="body1" color={'black'} sx={{ fontSize:{lg:16,sm:16,xs:14,marginBottom:12}}}>Building a supportive community where learners and educators can connect, collaborate, and inspire each other's success.</Typography></li>
                </ul>
              </Grid>
            </Grid>

          </Box>
        </Box>
        {/* <Box className='text-Container'> */}

          {/* <Typography variant='h1' >Our Mission</Typography>
        <Typography variant='bbody1'>At SER, we're committed to:</Typography>
        <ul className='list'>
          <li>Democratizing education by providing free and high-quality resources.</li>
          <li>Empowering students to achieve their full potential by fostering a love of learning and deep understanding.</li>
          <li>Building a supportive community where learners and educators can connect, collaborate, and inspire each other's success.</li>
        </ul>
        <Typography variant='h2' >Join SER today and unlock the world of academic possibilities!</Typography> */}

        {/* </Box> */}
      </Box>
    </Box>
  )
}
