import React, { Navigate } from "react-router-dom";
import PaperModel from "../screens/paperModel/paperModel";
import Home from "../screens/home/home";
import About from "../screens/about/about";
import Login from "../screens/login/Login";
import Signup from "../screens/signup/signup";
import TeacherProfile from "../screens/teachersProfile/teacherProfile";
import CoursesContainer from "../screens/home/coursesContaine";
import TeacherMaterial from "../screens/TeacherMaterial/TeacherMaterial";
import ProfileSetup from "../screens/profile/profileSetup";

// Tutor Pages
import MathTutorPage from "../screens/tutuorPage/MathTutorPage";
import EnglishTutorPage from "../screens/tutuorPage/EnglishTutorPage";
import AccountingTutorPage from "../screens/tutuorPage/AccountingTutorPage";
import EconomicsTutorPage from "../screens/tutuorPage/EconomicsTutorPage";
import ChemistryTutorPage from "../screens/tutuorPage/ChemistryTutorPage";
import PhysicsTutorPage from "../screens/tutuorPage/PhysicsTutorPage";
import BiologyTutorPage from "../screens/tutuorPage/BiologyTutorPage";


// A Level Tutors
import ALevelChemistryTutorPage from "../screens/ALevelTutors/ChemistryTutorPage";
import ALevelBiologyTutorPage from "../screens/ALevelTutors/BiologyTutorPage";
import ALevelMathTutorPage from "../screens/ALevelTutors/MathTutorPage";
import ALevelPhysicsTutorPage from "../screens/ALevelTutors/PhysicsTutorPage";


export const publicRoutes = [
    { index: true, element: <Navigate to="home" /> },
      {
        path: 'subject/:id',
        element: <PaperModel />
    },
    {
        path: "login",
        element: <Login />,
      },
      {
        path: "signup",
        element: <Signup />,
      },
      {
        path: "signup",
        element: <Signup />,
      },

      {
        path: "teacherProfile",
        element: <TeacherProfile/>,
      },
      {
        path: "profileSetup",
        element: <ProfileSetup/>,
      },
      {
        path: 'teacherMaterial/:id',
        element: <TeacherMaterial />
      },
      {
        path: "tutor",
        element: <CoursesContainer/>,
      },
      {
        path: "tutor",
        element: <CoursesContainer/>,
      },
      {
        path: "math-tutor",
        element: <MathTutorPage/>,
      },
      {
        path: "accounting-tutor",
        element: <AccountingTutorPage />,
      },
      {
        path: "economics-tutor",
        element: <EconomicsTutorPage/> ,
      },
      {
        path: "chemistry-tutor",
        element: <ChemistryTutorPage/> ,
      },
      {
        path: "english-tutor",
        element: <EnglishTutorPage/> ,
      },
      {
        path: "physics-tutor",
        element: <PhysicsTutorPage/> ,
      },
      {
        path: "Biology-tutor",
        element: <BiologyTutorPage/> ,
      },
      {
        path: "a-level-maths-tutors",
        element: <ALevelMathTutorPage/> ,
      },
      {
        path: "a-level-biology-tutors",
        element: <ALevelBiologyTutorPage/> ,
      },{
        path: "a-level-chemistry-tutors",
        element: <ALevelChemistryTutorPage/> ,
      },{
        path: "a-level-physics-tutors",
        element: <ALevelPhysicsTutorPage/> ,
      },
      
    {
        path: "home",
        // element: <Home />,
        children: [
            { index: true, element: <Navigate to="home" /> },
            {
                path: 'home',
                element: <Home />
            },
            {
                path: 'caie/:id',
                element: <PaperModel />
            },
            {
                path: 'ed-excel/:id',
                element: <PaperModel />
            },
            {
                path: 'aqa/:id',
                element: <PaperModel />
            },
            {
                path: 'ibl/:id',
                element: <PaperModel />
            },
            {
                path: 'about',
                element: <About/>
            },
        ]
    },
];
