import { Button } from '@mui/material';
import { styled } from '@mui/system';

const GradientButton = styled(Button)(({ theme, disabled }) => ({
    backgroundImage: 'linear-gradient(to top, rgb(8, 8, 8), rgb(3, 73, 75))', // Define gradient colors
    border: '2px solid', // Remove default border
    borderRadius: 8, // Add border radius
    color: 'white', // Set text color
    height: 60, // Set button height
    margin: '10px 0px',
    width: '101%',
    opacity: disabled ? 0.5 : 1,
    textTransform:'none',
  }));

  export default GradientButton;