import React from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/system";
import GradientTextComponent from "./customText";
import { CircularProgress } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

// Create a custom styled button

const GradientBorderButton = styled(Button)(({ theme, disabled }) => ({
  border: "2px solid #03494B", // Remove default border
  borderRadius: 3, // Add border radius
  height: 43, // Set button height
  margin: "0 5px",
  backgroundColor: "white",
  // width: '250px',
  opacity: disabled ? 0.5 : 1,
}));

const CustomButton = ({
  onClick,
  text,
  icon,
  className,
  textHide,
  disabled,
  isloading,
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <GradientBorderButton
      onClick={onClick}
      className={className}
      disabled={disabled}
    >
      {icon && (
        <IconButton color="inherit">
          <img src={icon} style={{ width: "20px", display: "block" }} />
        </IconButton>
      )}
      {isloading && <CircularProgress size={20} sx={{ marginRight: 1 }} />}
      {!isMobile && <GradientTextComponent>{text}</GradientTextComponent>}
      {isMobile && textHide && (
        <GradientTextComponent>{text}</GradientTextComponent>
      )}
    </GradientBorderButton>
  );
};

export default CustomButton;
