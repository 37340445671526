import React, { useEffect, useState } from "react";
import { Box, Grid, Menu, MenuItem, Typography } from "@mui/material";
import './navBar.scss'
import { navData as nav } from "./_nav";
import {  useNavigate } from "react-router-dom";
import MenuBar from "../../component/menuBar/menuBar";
import SubjectMenuBar from "../../component/menuBar/subjectMenuBar";


const Navbar = ({token}) => {
    const programs = ["CAIE", "ED-EXCEL","IB-MYP-DP"]
    console.log('token',token)
    const active = "active";
    const navigate = useNavigate();

    const role = localStorage.getItem('role');
    const [anchorEl, setAnchorEl] = useState(null);
    const [programId, setProgramId] = useState(null);
    const [navData, setNavData] = useState(role == "Teacher" ? nav.filter((x)=> !x.label.includes("SUPPORT")) : nav);


    
    const handleClose = ()=> {
        setAnchorEl(null);
    }

    const handleClick = (event, navItem) => {

        console.log("test",event.currentTarget,navItem);
        const active = navData.map(item => item.url === navItem.url ? { ...item, isActive: true } : { ...item, isActive: false })
        setNavData(active)

        if(navItem?.label == 'SEARCH'){
            navigate('/home');
            return
        }
        if(navItem?.label == 'SUBJECTS'){
            setAnchorEl(event.currentTarget);
            setProgramId(null);
            return
        }
        if(navItem?.label == 'STUDENTS SUPPORT'){
            // setAnchorEl(event.currentTarget);
            // setProgramId(null);
            navigate(token ? "home/studentsSupport" : '/login');
            return
        }
        if (programs.includes(navItem.label)) {
            setAnchorEl(event.currentTarget);
            setProgramId(navItem)
        } else {
            navigate(navItem.url)
        }
    };

    const handleHover = (event, navItem) => {

        if (programs.includes(navItem.label)) {
            // setAnchorEl(event.currentTarget);
            if (anchorEl !== event.currentTarget) {
                setAnchorEl(event.currentTarget);
              }
            setProgramId(navItem)
            const active = navData.map(item => item.url === navItem.url ? { ...item, isActive: true } : { ...item, isActive: false })
            setNavData(active)
    
        }
    };

    useEffect(() => {
        if (!anchorEl) {
            const active = navData.map(item => ({ ...item, isActive: false }))
            setNavData(active)
        }
    }, [anchorEl])



    return (
        <Grid container xs={12} display={{xs:'none', md:'none', lg:'flex'}} className="mainBox">
            <Box className="navBarList">
                <ul className="menu" >
                    {
                        navData.map((navItem, index) => (
                            <li key={index}
                                >
                                <a
                                    onClick={(e) => {
                                        handleClick(e, navItem)
                                    }}

                                    onMouseEnter={(e)=> handleHover(e,navItem)}

                                    className={
                                        navItem.isActive ?
                                            navItem.navData ?
                                                'active pop'
                                                : 'active'
                                            : ''
                                    }
                                // to={!programs.includes(navItem.label) && navItem.url}
                                >
                                    <Typography variant="body2" component="span" className="navText">
                                        {navItem.label}
                                    </Typography>
                                </a>

                            </li>
                        ))
                    }
                    { programId !== null ?
                    <MenuBar
                        aMenuBarnchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        program={programId}
                        setAnchorEl={setAnchorEl}
                    />:
                    <SubjectMenuBar
                        aMenuBarnchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        // program={programId}
                        setAnchorEl={setAnchorEl}
                    />
                    }
                </ul>
            </Box>
        </Grid>
    );
};

export default Navbar;
