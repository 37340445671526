import React, { useState, useEffect, useRef } from 'react';

//MUI Imports
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  Alert, 
  Grid, 
  Backdrop, 
  TextField,
  Typography, 
  IconButton, 
  AlertTitle, 
  Autocomplete, 
  InputAdornment, 
  CircularProgress,
} from '@mui/material';


//Local Imports
import httpService from '../../service/http.service';
import { api } from '../../interfaces/api.interface';
import { yearsList, months } from '../../utils/constants';
import GradientButton from '../../component/gradientButton';

//Library Imports
import axios from 'axios';
import Cookies from 'js-cookie';


//Input Field
const Input = ({ label, params, error, helperText }) => {
  return (
    <TextField
      {...params}
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
      }}
      required
      label={label}
      error={error}
      helperText={helperText}
    />
  )
}

export default function CustomTabPanel({ heading,URL }) {


  const [isLoading, setIsLoading] = useState(false);
  const [allSubjects, setAllSubjects] = useState([]);
  const [allLevels, setAllLevels] = useState([]);
  const [allPrograms, setAllPrograms] = useState([]);


  const [subject, setSubject] = useState('');
  const [level, setLevel] = useState('');
  const [program, setProgram] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const [noteName, setNoteName] = useState('');
  const [file, setFile] = useState('');
  const [videoName, setVideoName] = useState('');
  const [videoURi, setvideoURi] = useState('');


  const [showAlert, setShowAlert] = useState(false);

  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');
  const notesRef = useRef();

  const browseFileHandler = () => {
    console.log("Working")
    notesRef.current.click();
  };


  const uploadImage = (e) => {
    const file = e.target.files[0];
    setFile(file)
    console.log(file);
  };


  // API Calls
  const getPrograms = () => {
    httpService.call(
      api.getPrograms()
    ).then(res => {
      if (res.status === 200) {
        setAllPrograms(res.data.data["$values"])
        console.log("Programs====>", res.data.data["$values"])

      }
    }).catch((e) => {
      console.log(e)
    })
  }

  const getLevels = () => {
    httpService.call(
      api.getLevels()
    ).then(res => {
      if (res?.status === 200) {
        setAllLevels(res.data.data["$values"])
        console.log("Levels====>", res.data.data["$values"])
      }
    }).catch((e) => {
      console.log(e)
    })
  }
  const getSubjects = () => {
    httpService.call(
      api.getSubjects()
    ).then(res => {
      if (res?.status === 200) {
        setAllSubjects(res.data.data["$values"])
        console.log("Subjects====>", res.data.data["$values"])

      }
    }).catch((e) => {
      console.log(e)
    })
  }


  useEffect(() => {
    getPrograms()
    getLevels()
    getSubjects()
  }, [])

  
  const getHeaders = () => {
    const token = Cookies.get("token");
    const headers = {
      'Accept': 'application/json',
      "Content-Type":  heading == "Notes" ? 'multipart/form-data' : 'application/json',
      'Authorization': `Bearer ${token}`
    };
    console.log(headers);
    return headers;
  };

  const reset = () => {
    setProgram('');
    setSubject('');
    setLevel('');
    setMonth('');
    setYear('');
    setNoteName('');
    setVideoName('');
    setvideoURi('');
    setFile('');
    setApiError('');
  }


  const onSubmit = async () => {

    setIsLoading(true)

    const _data = {
      SubjectId: subject?.id,
      LevelId: level?.id,
      YearName: year?.value,
      MonthName: month?.value,
      ProgramId: program?.id,
      videoUrl: videoURi,
      videoName: videoName
    }
    
    const formData = new FormData();

    console.log(subject?.id, level?.id, year?.value, month?.value, program?.id);

    formData.append("SubjectId", subject?.id);
    formData.append("LevelId", level?.id);
    formData.append("YearName", year?.value);
    formData.append("MonthName", month?.value);
    formData.append("ProgramId", program?.id);

      formData.append("notesName", noteName);
      formData.append("notesPDF", file);  
   

    console.log([...formData]);
    const headers = getHeaders();

    // return

    try {
      const response = await axios['post'](URL, heading == "Notes" ? formData : _data, { headers })
      const data = response?.data;

      console.log("data======>",data)
      
      const {succeeded} = data;

      setIsLoading(false)
      if (succeeded) {
          reset()
          setShowAlert(true)
      } else if (!succeeded) {
        data?.messages?.$values?.map((msg) => console.log(msg))
      } else {
        throw new Error(response);
      }
    }
    catch (e) {
      setIsLoading(false)
      const errorMsg = e?.response?.data
      console.log("Error message", errorMsg);
      setApiError(errorMsg)
    }
  }



  const validateData = () => {
    const newErrors = {};

    if (!program) {
      newErrors.program = 'Program is required';
    }
    if (!level) {
      newErrors.level = 'Level is required';
    }
    if (!subject) {
      newErrors.subject = 'Subject is required';
    }
    if (!year) {
      newErrors.year = 'Year is required';
    }
    if (!month) {
      newErrors.month = 'Month is required';
    }

    if(heading == "Notes"){
      if (!noteName) {
        newErrors.name = 'Note name is required';
      } else if (noteName.length < 3) {
        newErrors.password = "Name must be at least of length 3"
      }
      if (!file) {
        newErrors.file = 'File is required';
      }  

    }else{
      if (!videoURi) {
        newErrors.url = 'URL is required';
      }
      if (!videoName) {
        newErrors.video = 'Name is required';
      }
  
    }
    
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      onSubmit()
    }
  }



  return (
    <form onSubmit={(e) => e.preventDefault()} style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
     
      {showAlert && <Alert severity="success" onClose={() => setShowAlert(false)}>
        <AlertTitle>Success</AlertTitle>
        Your notes have been uploaded successfully
      </Alert>}

      {apiError && <Alert severity='error' onClose={() => setApiError('')}>
        <AlertTitle>Error</AlertTitle>
        {apiError}
      </Alert>}

      <Typography variant="h2" style={{ fontSize: 32, marginBottom: 20, textAlign: 'center', marginTop: 24 }}>
        Upload {heading}
      </Typography>


      <Typography style={{ alignSelf: 'flex-start', fontWeight: 'bold', fontSize: 16, color: '#03494B', marginBottom: 8 }} className={"heading"}>
        Course Detail
      </Typography>

      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>

        <Grid item xs={12} lg={6}>

          <Autocomplete
            disablePortal
            value={program ? program : null}
            options={allPrograms.map((pg) => {
              return { label: pg.programName, id: pg.id };
            })}
            sx={{ width: '100%' }}
            onChange={(event, value, index) => {
              setProgram(value)
              setLevel('');
              setSubject('');
            }}
            renderInput={(params) => <Input label={'Program'} params={params} error={!!errors.program} helperText={errors.program} />
            }
          />
        </Grid>

        <Grid item xs={12} lg={6}>

          <Autocomplete
            disablePortal
            value={level ? level : null}
            disabled={!program}
            options={allLevels.filter(e => e.programId == program?.id).map((lvl) => {
              return { label: lvl.levelName, id: lvl.id };
            })}
            sx={{ width: '100%' }}
            onChange={(event, value, index) => {
              setLevel(value)
              setSubject('');
            }}
            renderInput={(params) => <Input label={'Level'} params={params} error={!!errors.level} helperText={errors.level} />
            }
          />
        </Grid>

        <Grid item xs={12} lg={6}>

          <Autocomplete
            disablePortal
            value={subject ? subject : null}
            disabled={!level}
            options={allSubjects.filter(e => e.levelId == level?.id).map((sub) => {
              return { label: sub.subjectName, id: sub.id };
            })}
            sx={{ width: '100%' }}
            onChange={(event, value, index) => setSubject(value)}
            renderInput={(params) => <Input label={'Subject'} params={params} error={!!errors.subject} helperText={errors.subject} />
            }
          />
        </Grid>

        <Grid item xs={12} lg={6}>

          <Autocomplete
            disablePortal
            value={year ? year : null}
            options={yearsList.map((x) => { return { label: x, value: x } })}
            sx={{ width: '100%' }}
            onChange={(event, value, index) => setYear(value)}
            renderInput={(params) => <Input label={'Year'} params={params} error={!!errors.year} helperText={errors.year} />
            }
          />
        </Grid>

        <Grid item xs={12}>

          <Autocomplete
            disablePortal
            value={month ? month : null}
            options={months.map((x) => { return { label: x, value: x } })}
            sx={{ width: '100%' }}
            onChange={(event, value, index) => setMonth(value)}
            renderInput={(params) => <Input label={'Month'} params={params} error={!!errors.month} helperText={errors.month} />
            }
          />
        </Grid>

      </Grid>

      <Typography style={{ alignSelf: 'flex-start', fontWeight: 'bold', fontSize: 16, color: '#03494B', margin: "8px 0px" }} className={"heading"}>
        Upload File
      </Typography>

      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>

        { heading == "Notes" ?
        <>
        <Grid item xs={12} lg={6}>
          <TextField
            placeholder="Name"
            type={'text'}
            label="Name"
            value={noteName ? noteName : null}
            onChange={(e) => setNoteName(e.target.value)}
            fullWidth
            error={!!errors.name}
            helperText={errors.name}
          />


        </Grid>

        <Grid item xs={12} lg={6}>

          <TextField
            placeholder="Upload File"
            type={'text'}
            disabled
            value={file?.name}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => browseFileHandler()}
                    edge="end"
                  >
                    <FileUploadIcon sx={{ color: 'grey' }} />

                  </IconButton>
                </InputAdornment>
              )

            }}

            fullWidth
            error={!!errors.file}
            helperText={errors.file}

          />

          <input
            type="file"
            name="uploadAsset"
            id="uploadAsset"
            hidden
            onChange={uploadImage}
            ref={notesRef}
          />

        </Grid>
        </> : 
          
          <>
        <Grid item xs={12} lg={6}>
          <TextField
            placeholder="Name"
            type={'text'}
            label="Name"
            value={videoName ? videoName : null}
            onChange={(e) => setVideoName(e.target.value)}
            fullWidth
            error={!!errors.video}
            helperText={errors.video}
          />


        </Grid>

        <Grid item xs={12} lg={6}>

          <TextField
            placeholder="Video URL"
            defaultChecked
            type={'text'}
            value={ videoURi }
            onChange={(e) => setvideoURi(e.target.value)}
            fullWidth
            error={!!errors.url}
            helperText={errors.url}
          />
        </Grid>

        </>
          }
      </Grid>
      
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <GradientButton type='file' onClick={validateData}>
        <Typography variant='h6' style={{ fontWeight: 'bold' }}> Upload</Typography>
      </GradientButton>
    </form>

  )
}
