import { createSlice } from "@reduxjs/toolkit";


const DataSlice = createSlice({
    name:'data',
    initialState:{
        subjects:[]        
    },
    reducers:{
        setSubjects:(state,action)=>{
            console.log("action.payload",action.payload)
            state.subjects = action.payload
        }
    }
})

export const {setSubjects} = DataSlice.actions;
export default DataSlice.reducer;