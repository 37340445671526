import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  Avatar,
  Chip,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

// Icons
import BadgeIcon from "@mui/icons-material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import InfoIcon from "@mui/icons-material/Info";

// Api
import httpService from "../../service/http.service";
import { api } from "../../interfaces/api.interface";

const ProfileView = ({ navigation }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [avatarImage, setAvatarImage] = useState("");
  const [profile, setProfile] = useState(null);

  const handleAvatarClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
    setOpenDialog(false); // Close dialog after selecting file
  };

  const getTeacherProfile = () => {
    httpService
      .call(api.getTeacherProfile(), true)
      .then((res) => {
        console.log("Teacher Profile =====>", res?.data?.data);
        const { succeeded, data } = res?.data;

        if (succeeded) {
          setProfile(data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    console.log("test");
    getTeacherProfile();
  }, []);

  return (
    <Box style={{ width: "100%" }}>
      <Box className={"container"}>
        <div className="root-div" style={{ width: "60%" }}>
          <Avatar
            src={avatarImage}
            alt="Profile Image"
            sx={{
              width: 100,
              height: 100,
              margin: "auto",
              cursor: "pointer",
              marginBottom: 5,
            }}
          />
          {/* <Typography
            variant="h4"
            component={"label"}
            style={{ fontSize: 12, marginBottom: 20 }}
            onClick={handleAvatarClick}
          >
            Change Profile
          </Typography> */}

          <Typography variant="h4" style={{ fontSize: 48, marginBottom: 20 }}>
            {profile?.title} {profile?.firstName} {profile?.lastName}
          </Typography>

          <Grid container spacing={3} style={{ width: "80%", margin: "auto" }}>
            <Grid item xs={12} sm={6}>
              <Paper
                elevation={3}
                className="info-paper"
                style={{ padding: 12 }}
              >
                <BadgeIcon
                  sx={{ fontSize: 24, color: "#03494B", marginRight: 10 }}
                />
                <Typography variant="subtitle1">Name</Typography>
                <Typography variant="body1">
                  {profile?.firstName} {profile?.lastName}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper
                elevation={3}
                className="info-paper"
                style={{ padding: 12 }}
              >
                <MailIcon
                  sx={{ fontSize: 24, color: "#03494B", marginRight: 10 }}
                />
                <Typography variant="subtitle1">Email</Typography>
                <Typography variant="body1">{profile?.email}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper
                elevation={3}
                className="info-paper"
                style={{ padding: 12 }}
              >
                <PhoneIcon
                  sx={{ fontSize: 24, color: "#03494B", marginRight: 10 }}
                />
                <Typography variant="subtitle1">Phone Number</Typography>
                <Typography variant="body1">{profile?.phoneNo}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper
                elevation={3}
                className="info-paper"
                style={{ padding: 12 }}
              >
                <InfoIcon
                  sx={{ fontSize: 24, color: "#03494B", marginRight: 10 }}
                />
                <Typography variant="subtitle1">Short Intro</Typography>
                <Typography variant="body1">{profile?.shortIntro}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper
                elevation={3}
                className="info-paper"
                style={{ padding: 12 }}
              >
                <InfoIcon
                  sx={{ fontSize: 24, color: "#03494B", marginRight: 10 }}
                />
                <Typography variant="subtitle1">Examination System</Typography>
                <Typography variant="body1">{profile?.examSystem}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper
                elevation={3}
                className="info-paper"
                style={{ padding: 12 }}
              >
                <InfoIcon
                  sx={{ fontSize: 24, color: "#03494B", marginRight: 10 }}
                />
                <Typography variant="subtitle1">Level</Typography>
                <Typography variant="body1">{profile?.levelsName}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper
                elevation={3}
                className="info-paper"
                style={{ padding: 12 }}
              >
                <InfoIcon
                  sx={{ fontSize: 24, color: "#03494B", marginRight: 10 }}
                />
                <Typography variant="subtitle1">Qualification</Typography>
                <Typography variant="body1">
                  {profile?.qualification}
                </Typography>
              </Paper>
            </Grid>

            {/* <Grid item xs={12} sm={6}>
              <Paper
                elevation={3}
                className="info-paper"
                style={{ padding: 12 }}
              >
                <InfoIcon
                  sx={{ fontSize: 24, color: "#03494B", marginRight: 10 }}
                />
                <Typography variant="subtitle1">
                  Professional Qualification
                </Typography>
                <Typography variant="body1">
                  {profile?.professionalQualification}
                </Typography>
              </Paper>
            </Grid> */}

            <Grid item xs={12} sm={6}>
              <Paper
                elevation={3}
                className="info-paper"
                style={{ padding: 12 }}
              >
                <InfoIcon
                  sx={{ fontSize: 24, color: "#03494B", marginRight: 10 }}
                />
                <Typography variant="subtitle1">Institution</Typography>
                <Typography variant="body1">
                  {profile?.expIntitution}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper
                elevation={3}
                className="info-paper"
                style={{ padding: 12 }}
              >
                <InfoIcon
                  sx={{ fontSize: 24, color: "#03494B", marginRight: 10 }}
                />
                <Typography variant="subtitle1">Years Served</Typography>
                <Typography variant="body1">{profile?.expYears}</Typography>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Box>

      {/* Upload Picture Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Upload Profile Picture</DialogTitle>
        <DialogContent>
          <input type="file" onChange={handleFileChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProfileView;
