import React, { useState, useEffect } from "react";

//  MUI components
import {
  TextField,
  Typography,
  MenuItem,
  InputAdornment,
  Box,
  Alert,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";

// Third Party
import Cookies from "js-cookie";
import { Navigate, Link, useNavigate } from "react-router-dom";

//Icons
import MailIcon from "@mui/icons-material/Mail";
import LockIcon from "@mui/icons-material/Lock";
import BadgeIcon from "@mui/icons-material/Badge";
import PhoneIcon from "@mui/icons-material/Phone";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// Api
import httpService from "../../service/http.service";
import { api } from "../../interfaces/api.interface";

// Custom Components
import GradientButton from "../../component/gradientButton";
import ModalWithCheckboxes from "../../component/modal/ProgramModla";

// Css
import "./Signup.scss";
import CourseSelection from "../courseSelection/CourseSelection";

const Signup = () => {
  // states
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState({ iSError: false, message: "" });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [visible, setVisible] = useState(false);
  const [teacherModal, setTeacherModal] = useState(false);

  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleShowConfirmPassword = () =>
    setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const SetToken = (token) => {
    Cookies.set("token", token);
  };

  const clearFields = () => {
    setName("");
    setUsername("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setRole("");
    setPhone("");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [apiError]);

  const HandleNavigation = () => {
    navigate("/login");
  };

  const handleSignUp = () => {
    let names = name?.split(" ");

    const data = {
      firstName: names[0],
      lastName: names.length > 1 ? names[1] : names[0],
      email: email,
      userName: username,
      password: password,
      confirmPassword: confirmPassword,
      phoneNumber: phone,
      activateUser: true,
      autoConfirmEmail: true,
      type: role,
    };

    console.log("Params ==>", data);

    setIsLoading(true);

    httpService
      .call(api.signupUser(), data)
      .then((res) => {
        console.log("Sign Up API ==>", res?.data);
        const { succeeded, messages } = res?.data;
        if (succeeded) {
          // console.log("success");
          //   clearFields();
          //   setIsLoading(false);
          //   HandleNavigation();
          handleSignIn();
        } else if (!succeeded) {
          const { $values } = messages;
          setApiError({ iSError: true, message: $values[0] });
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e?.data?.erros);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateData();
  };

  const handleSignIn = () => {
    const data = {
      email: email,
      password: password,
    };

    console.log("Login Params", data);

    httpService
      .call(api.loginUser(), data)
      .then((res) => {
        console.log("Login Api ==>", res?.data);

        const { succeeded, messages, data } = res?.data;

        if (succeeded) {
          const { token } = data;
          console.log("Token ==>", token);
          SetToken(token);
          getRoles();
        } else if (!succeeded) {
          const { $values } = messages;
          setApiError({ iSError: true, message: $values[0] });
          setIsLoading(false);
        } else {
          throw new Error(res.error);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  const getRoles = () => {
    httpService
      .call(api.getRoles(), null, true)
      .then((res) => {
        console.log("Role API ==>", res.data);
        const { succeeded, messages } = res?.data;

        if (succeeded) {
          clearFields();
          if (messages?.$values[0] == "Teacher") {
            localStorage.setItem("role", "Teacher");
            setIsLoading(false);
            getTeacherProfile();
          } else {
            localStorage.setItem("role", "Student");
            isFirstLogin();
          }
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  const isFirstLogin = () => {
    httpService
      .call(api.isFirstLogIn(), null, true)
      .then((res) => {
        console.log("First=====>", res.data);
        const { succeeded, messages, data } = res?.data;

        if (data) {
          navigate("/studentDashboard");
        } else {
          setVisible(true);
          // navigate("/courseSelection");
          clearFields();
        }

        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  const getTeacherProfile = () => {
    httpService
      .call(api.getTeacherProfile(), true)
      .then((res) => {
        console.log("Teacher Profile ==>", res.data);
        const { succeeded, data } = res?.data;

        if (succeeded) {
          if (data?.title) {
            navigate("/teacherDashboard");
          } else {
            navigate("/profileSetup", { state: data });
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const validateData = () => {
    const newErrors = {};
    if (!name) {
      newErrors.name = "Name is required";
    }
    if (!username) {
      newErrors.username = "Username is required";
    }
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is invalid";
    }
    if (!password) {
      newErrors.password = "Password is required";
    } else if (password.length < 8) {
      newErrors.password = "Password must be at least of length 8";
    } else if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/.test(password)) {
      newErrors.password =
        "Your password must contain at least one uppercase letter, one lowercase letter, and one number.";
    }
    if (!phone) {
      newErrors.phone = "phone number is required";
    }
    if (phone.length < 11) {
      newErrors.phone = "phone must have 11 numbers";
    }
    if (username.length < 6) {
      newErrors.username = "UserName must be at least of length 6";
    }
    if (!confirmPassword) {
      newErrors.confirmPassword = "Confirm Password is required";
    } else if (confirmPassword !== password) {
      console.log(confirmPassword);
      console.log(password);

      newErrors.confirmPassword = "Passwords do not match";
    }
    if (!role) {
      newErrors.role = "Role is required";
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      handleSignUp();
    }
  };

  return (
    <Box style={{ width: "100%" }}>
      <Box className={"container"}>
        <div className="root-div">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {apiError.iSError && (
            <Alert variant="outlined" severity="error">
              {apiError.message}
            </Alert>
          )}

          <CourseSelection
            open={visible}
            // navigationHandler={() => navigate("/studentDashboard")}
          />
          <ModalWithCheckboxes
            open={teacherModal}
            isTeacher={true}
            navigationHandler={() => navigate("/teacherDashboard")}
          />
          <Typography variant="h1" style={{ fontSize: 48, marginBottom: 20 }}>
            Sign Up
          </Typography>
          <form className={"formContainer"} onSubmit={handleSubmit}>
            <Typography
              style={{
                alignSelf: "flex-start",
                fontWeight: "bold",
                fontSize: 20,
                color: "#03494B",
              }}
              className={"heading"}
            >
              Select Option
            </Typography>

            <TextField
              select
              variant="outlined"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              fullWidth
              margin="normal"
              style={{ marginTop: 5 }}
              error={!!errors.role}
              helperText={errors.role}
              SelectProps={{
                MenuProps: { style: { height: 185, maxWidth: 200 } },
              }}
            >
              <MenuItem value="Teacher">Teacher</MenuItem>
              <MenuItem value="Student">Student</MenuItem>
            </TextField>

            <TextField
              placeholder="First & Last Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BadgeIcon sx={{ color: "#03494B" }} />
                  </InputAdornment>
                ),
              }}
              fullWidth
              margin="normal"
              error={!!errors.name}
              helperText={errors.name}
            />
            <TextField
              placeholder="Username"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle sx={{ color: "#03494B" }} />
                  </InputAdornment>
                ),
              }}
              fullWidth
              margin="normal"
              error={!!errors.username}
              helperText={errors.username}
            />
            <TextField
              placeholder="E-mail"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon sx={{ color: "#03494B" }} />
                  </InputAdornment>
                ),
              }}
              fullWidth
              margin="normal"
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: "#03494B" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              margin="normal"
              error={!!errors.password}
              helperText={errors.password}
            />
            <TextField
              type={showConfirmPassword ? "text" : "password"}
              variant="outlined"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: "#03494B" }} className="icon" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              margin="normal"
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
            />

            <TextField
              type="tel"
              variant="outlined"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon sx={{ color: "#03494B" }} />
                  </InputAdornment>
                ),
              }}
              fullWidth
              margin="normal"
              error={!!errors.phone}
              helperText={errors.phone}
            />

            <GradientButton onClick={handleSubmit}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {" "}
                Sign Up
              </Typography>
            </GradientButton>
          </form>
          <Typography variant="span" style={{ color: "#B9B9B9" }}>
            Already have an account?{" "}
            <Link to={"/Login"}>
              <Typography
                variant="span"
                color={"#03494B"}
                style={{ fontWeight: "bold" }}
              >
                {" "}
                Sign in
              </Typography>
            </Link>
          </Typography>
        </div>
      </Box>
    </Box>
  );
};

export default Signup;
