import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, IconButton, Grid } from '@mui/material';
import './dashboard.scss';
import GradientButton from '../../../component/gradientButton';
import CustomButton from '../../../component/button';
import { api } from '../../../interfaces/api.interface';
import httpService from '../../../service/http.service';
import { useNavigate } from 'react-router-dom';
import CourseModal from '../../../component/modal/courseModal';
import SchoolIcon from '@mui/icons-material/School';
import NoteIcon from '@mui/icons-material/Note';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ShareIcon from '@mui/icons-material/Share';
import DataModal from '../../../component/modal/DataModal';

const Tile = ({ title, icon: Icon, onClick }) => {
  return (
    <Card className="tile" onClick={onClick}>
      <CardContent className="tile-content">
        <IconButton className="tile-icon">
          <Icon className="tile-icon" />
        </IconButton>
        <Typography variant="h6" component="div" className="tile-title">
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};

function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState(null);
  const [open, setOpen] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const navigate = useNavigate();

  const getStudentCourses = () => {
    setIsLoading(true);
    httpService.call(api.getStudentCourses(), null, true)
    .then(res => {
        console.log("student courses",res?.data?.data);
        const { succeeded, data } = res?.data;
        if (succeeded) {
          setCourses(data);
        }
        setIsLoading(false);
      })
      .catch(e => {
        setIsLoading(false);
        console.error(e);
      });
  };

  useEffect(() => {
    getStudentCourses();
  }, []);

  return (
    <Box className='mainContainer'>
      <Box className='subContainer'>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <Tile title={'Course'} icon={SchoolIcon} onClick={() => setOpen(true)} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Tile title={'Notes'} icon={NoteIcon} onClick={() => setOpenNotesModal(true)} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Tile title={'Videos'} icon={VideoLibraryIcon} onClick={() => setOpenVideoModal(true)} />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4}>
            <Tile title={'Exams'} icon={AssignmentIcon} onClick={() => setOpen(true)} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Tile title={'Send Feedback'} icon={FeedbackIcon} onClick={() => setOpen(true)} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Tile title={'Share App'} icon={ShareIcon} onClick={() => setOpen(true)} />
          </Grid> */}
        </Grid>
        {open && <CourseModal open={open} data={courses}  handleClose={() => setOpen(false)} />}
        {openNotesModal && <DataModal open={openNotesModal} data={courses} isVideo={false} handleClose={() => setOpenNotesModal(false)} />}
        {openVideoModal && <DataModal open={openVideoModal} data={courses} isVideo={true} handleClose={() => setOpenVideoModal(false)} />}
      </Box>
    </Box>
  );
}

export default Dashboard;
