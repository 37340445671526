import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, Autocomplete } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const DataModal = ({ open, handleClose, navigationHandler, data, isVideo }) => {
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [subjects, setSubjects] = useState([]);

  const navigate = useNavigate();
  const processData = (data) => {
    const levels = data.studentLevels.$values.map(level => ({
      id: level.levelId,
      title: level.levelName,
      subjects: level.subjects.$values.map(subject => ({
        id: subject.subjectId,
        title: subject.subjectName
      }))
    }));
    return { levels };
  };

  const staticData = processData(data);

  const handleLevelChange = (event, value) => {
    setSelectedLevel(value);
    const selectedLevelData = staticData.levels.find(level => level.id === value?.id);
    setSubjects(selectedLevelData ? selectedLevelData.subjects : []);
    setSelectedSubject(null); // Reset subject selection when level changes
  };

  const handleSubjectChange = (event, value) => {
    setSelectedSubject(value);
  };

  const handleDone = () => {
    const selectedData = {
      levelId: selectedLevel.id,
      subjectId: selectedSubject.id
    };

    console.log("Selected Data:", selectedData);
    navigate(`/studentMaterial/${selectedSubject.id}`, { state: isVideo ? "Video" : "Notes" });
    console.log(selectedSubject);
    // You can perform any action with the selected data, like calling an API or updating state
    handleClose(); // Close the modal after done
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Select Level and Subject
        </Typography>
        <Autocomplete
          options={staticData.levels}
          getOptionLabel={(option) => option.title}
          value={selectedLevel}
          onChange={handleLevelChange}
          renderInput={(params) => <TextField {...params} label="Select Level" variant="outlined" margin="normal" />}
          // sx={{ marginBottom: 2 }}
        />
        <Autocomplete
          options={subjects}
          getOptionLabel={(option) => option.title}
          value={selectedSubject}
          onChange={handleSubjectChange}
          renderInput={(params) => <TextField {...params} label="Select Subject" variant="outlined" margin="normal" />}
          disabled={!selectedLevel}
          // sx={{ marginBottom: 2 }}
        />
        <Button
          variant="contained"
          onClick={handleDone}
          sx={{
            mt: 2,
            backgroundColor: selectedLevel && selectedSubject ? '#03494b' : 'primary.main',
            '&:hover': {
              backgroundColor: selectedLevel && selectedSubject ? '#03494b' : 'primary.dark',
            }
          }}
          disabled={!selectedLevel || !selectedSubject}
        >
          Done
        </Button>
      </Box>
    </Modal>
  );
};

export default DataModal;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
  display: 'flex',
  flexDirection: 'column',
};
