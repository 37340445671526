import axios from "axios";
import Cookies from 'js-cookie';


const getHeaders = (isTokenUse) => {

  if (isTokenUse) {
    const token = Cookies.get("token");
    axios.defaults.headers.common["authorization"] = `Bearer ${token}`;
  }
  console.log(isTokenUse,'isTokenUse')
  
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };
  return headers;
};

const call = (api, data,isTokenUse=false) => {
  let headers = getHeaders(isTokenUse);
  console.log(axios.defaults.headers.common);
  return axios[api.method]?.(api.url, JSON.stringify(data), { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error)
    });
};

export default {
  call,
};
