import React from 'react';
import { Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography, Paper } from '@mui/material';
import "./ReequestForm.scss"
const TutorRequestForm = () => {
  return (
    <Paper 
      sx={{
        padding: '30px',
        borderRadius: '16px',
        backgroundColor: '#eee',
        maxWidth: '80%',
        margin: 'auto',
        marginTop:"50px",
        marginBottom:"50px",
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={12} md={8}>


            <Typography  className="headingText" variant="h2" color={'#555'} sx={{ fontSize:{lg:42,sm:30,xs:25},marginBottom:1,color:"#555"}} >            Didn't find a suitable tutor?
            </Typography>
        <Typography className="headingText" variant="h2" color={'#555'} sx={{ fontSize:{lg:24,sm:18,xs:16},marginBottom:3}}>             Leave a request, we will help
        </Typography>

        <Typography className="headingText" variant="h3" color={'#555'} sx={{ fontSize:{lg:20,sm:15,xs:12},marginBottom:1}}>Let's see how the lesson will go</Typography>
        <Typography className="headingText" variant="h3" color={'#555'} sx={{ fontSize:{lg:20,sm:15,xs:12},marginBottom:1}}>            Answer questions, fix key goals
        </Typography>

        

{/* 
            <Typography  className="headingText" variant="body1"  style={{ margin: 12 }}>             Let's see how the lesson will go
            </Typography> */}

          {/* <Typography variant="h5" gutterBottom>
            Didn't find a suitable tutor?
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Leave a request, we will help
          </Typography>
          <Typography variant="body2" gutterBottom>
            Let's see how the lesson will go
          </Typography>
          <Typography variant="body2">
            Answer questions, fix key goals
          </Typography> */}
        </Grid>
        <Grid item xs={12} md={4} style={{display:'flex',flexDirection:'column'}} >
          <TextField fullWidth placeholder="Full Name" margin="normal" variant="outlined" sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
                backgroundColor:'white',
                padding: '10px',
                '& input': {
                  padding: '10px',
                },
              },
              '& .MuiInputBase-input::placeholder': {
                color: 'grey',
              },
            }}/>
          <TextField fullWidth placeholder="Mobile number" margin="normal" variant="outlined" sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
                padding: '10px',
                backgroundColor:'white',
                '& input': {
                  padding: '10px',
                },
              },
              '& .MuiInputBase-input::placeholder': {
                color: 'grey',
              },
            }}/>
          <TextField fullWidth placeholder="Email" margin="normal" variant="outlined" sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
                padding: '10px',
                backgroundColor:'white',
                '& input': {
                  padding: '10px',
                },
              },
              '& .MuiInputBase-input::placeholder': {
                color: 'grey',
              },
            }}/>
          <FormControlLabel
            control={<Checkbox />}
            label={<Typography style={{fontSize:12,color:'gray'}}> I agree to the privacy policy.</Typography>}
            sx={{ mt: 1 }}
          />
<Button variant="contained" size="large"  sx={{ mt: 2,color:'white',alignSelf:'center',backgroundColor:'grey',borderRadius:3 }}>
                SUBMIT
              </Button>        
               </Grid>
      </Grid>
    </Paper>
  );
};

export default TutorRequestForm;
