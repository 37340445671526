import React, { useState } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Box,
  Typography,
  Button,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  Grid,
} from '@mui/material';

const SubjectSelectionModal = ({ subjects, open, onClose, onSave }) => {
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const handleSave = () => {
    onSave(selectedSubjects);
    onClose();
  };

  const handleToggle = (subject) => () => {
    const currentIndex = selectedSubjects.findIndex((s) => s.id === subject.id);
    const newSelected = [...selectedSubjects];

    if (currentIndex === -1) {
      newSelected.push(subject);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelectedSubjects(newSelected);
  };

  return (
    <Modal
      open={open}
      closeAfterTransition
      disableBackdropClick // Prevent closing on backdrop click
      disableEscapeKeyDown // Prevent closing on ESC key press
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            backgroundColor: '#ffffff',
            padding: '32px',
            borderRadius: '8px',
            maxWidth: '50%',
            maxHeight: '50%',
            overflowY: 'auto', // Enable scrolling
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Select Subjects
          </Typography>
          <FormControl component="fieldset">
            <FormGroup>
              {subjects.map((subject) => (
                <FormControlLabel
                  key={subject.id}
                  control={
                    <Checkbox
                      checked={selectedSubjects.some((s) => s.id === subject.id)}
                      onChange={handleToggle(subject)}
                    />
                  }
                  label={subject.title}
                />
              ))}
            </FormGroup>
          </FormControl>
          <Grid container justifyContent="flex-end" sx={{ marginTop: '16px' }}>
            <Grid item>
              <Button variant="outlined" sx={{color:'#03494b'}} onClick={onClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item sx={{ marginLeft: '8px' }}>
              <Button variant="contained" sx={{backgroundColor:'#03494b'}} onClick={handleSave}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SubjectSelectionModal;
