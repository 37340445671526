import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import "./StudentNotes.scss";
import FilterBar from "../../component/filterBar/filterBar";
import httpService from "../../service/http.service";
import { api } from "../../interfaces/api.interface";
import Pagination from "../../component/pagination/pagination";
import { useParams } from "react-router-dom";
import { downloadPDFsAsZip } from "../../utils/Utils";
import CustomButton from "../../component/button";
import NotesTable from "../../component/table/notesTable";

const StudentVideos = () => {
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const [search, setSearch] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [paperType, setPaperType] = useState("");

  const [selectedPDF, setSelectedPDF] = useState([]);
  const [isMultipleLoading, setIsMultipleLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const params = {};
    params.skip = 0;
    params.take = 10;
    if (id) {
      getVideosBySubjectID();
    }
    return () => {
      setVideos([]);
      setCount(0);
    };
  }, [id]);

  const getvideosList = (data) => {
    setIsLoading(true);
    httpService
      .call(api.getvideos(data.skip, data.take), data)
      .then((res) => {
        if (res.data.succeeded) {
          setCount(res.data.data.totalCount);
          setVideos(res.data.data.videosViews.$values);
          if (res.data.data.videosViews.$values) {
            setIsLoading(false);
          }
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };
  const getVideosBySubjectID = () => {
    setIsLoading(true);
    httpService
      .call(api.getVideosForStudentBySubjectId(id))
      .then((res) => {
        if (res.data.succeeded) {
          console.log("IDD", id);
          const _data = res.data.data?.$values;
          console.log("Videossss", _data);
          setVideos(_data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  const filterMonthYear = (value) => {
    if (month && year) {
      if (value.month == month && value.year == year) {
        return value;
      }
    } else if (month && !year) {
      if (value.month == month) {
        return value;
      }
    } else if (!month && year) {
      if (value.year == year) {
        return value;
      }
    } else {
      return value;
    }
  };

  const filterPaperType = (value) => {
    if (paperType) {
      if (value.paperType.toLowerCase() === paperType.toLowerCase()) {
        return value;
      }
    } else {
      return value;
    }
  };
  const filterSearch = (value) => {
    if (search) {
      if (value?.videoName?.toLowerCase()?.includes(search)) {
        return value;
      }
    } else {
      return value;
    }
  };

  const paperData = () =>
    videos.filter(
      (e) => filterMonthYear(e) && filterPaperType(e) && filterSearch(e)
    );

  const handleSelectedPdf = (url) => {
    if (!selectedPDF.includes(url)) {
      selectedPDF.push(url);
    } else {
      let index = selectedPDF.indexOf(url);
      selectedPDF.splice(index, 1);
    }
    console.log(selectedPDF);
    setSelectedPDF(selectedPDF);
  };

  const handleChange = (url) => {
    if (selectedPDF.includes(url)) {
      setSelectedPDF(selectedPDF.filter((item) => item !== url));
    } else {
      setSelectedPDF([...selectedPDF, url]);
    }
  };

  const multiPdfDownload = () => {
    console.log(selectedPDF);
    if (selectedPDF.length > 0) {
      downloadPDFsAsZip(selectedPDF, setIsMultipleLoading);
      setSelectedPDF([]);
    }
  };

  return (
    <Box className="mainContainer">
      <Typography variant="h1" className="heading">
        Videos
      </Typography>
      <FilterBar
        setSearch={setSearch}
        setYear={setYear}
        setMonth={setMonth}
        setPaperType={setPaperType}
      />
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <CircularProgress size={50} sx={{ margin: 5 }} />
          <Typography component="h1" variant="h3">
            Loading....
          </Typography>
        </Box>
      ) : (
        <NotesTable
          papers={videos}
          handleSelectedPdf={handleChange}
          selectedPDF={selectedPDF}
          isVideo={true}
        />
      )}
      {!id && count > 10 && (
        <Pagination
          count={count}
          page={page}
          onChange={(value) => {
            setPage(value);
            const params = {};
            params.skip = (value - 1) * 10;
            params.take = 10;
            getvideosList(params);
          }}
        />
      )}
      {selectedPDF.length > 0 && (
        <Box className="bottomBar">
          <CustomButton
            onClick={multiPdfDownload}
            text="Multiple Download"
            color="green"
            isloading={isMultipleLoading}
          />
        </Box>
      )}
    </Box>
  );
};
export default StudentVideos;
