import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import "./paperModel.scss";
import FilterBar from "../../component/filterBar/filterBar";
import Table from "../../component/table/table";
import httpService from "../../service/http.service";
import { api } from "../../interfaces/api.interface";
import Pagination from "../../component/pagination/pagination";
import { useParams } from "react-router-dom";
import { downloadPDFsAsZip } from "../../utils/Utils";
import CustomButton from "../../component/button";

const PaperModel = () => {
    const [papers, setPapers] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(1);

    const { id } = useParams();

    console.log("id ==>",id)
    const [search, setSearch] = useState('')
    const [year, setYear] = useState('')
    const [month, setMonth] = useState('')
    const [paperType, setPaperType] = useState('')

    const [banner,setBanner] =  useState(null);


    const [selectedPDF, setSelectedPDF] = useState([])
    const [isMultipleLoading, setIsMultipleLoading] = useState(false)

    useEffect(() => {
        const params = {};
        params.skip = 0;
        params.take = 10;
        if (id) {
            getPapersListBySubjectId()
            getBanners()
        }
        return (() => {
            setPapers([])
            setCount(0)
        })
    }, [id])


    const getBanners = () => {
        setIsLoading(true)
        httpService.call(api.getBanners()).then(res => {
            console.log(res?.data?.data?.$values.length)
            if (res.data.succeeded) {
                const data = res?.data?.data?.$values
                console.log("subject Banner ==>",data);
                const filter = data.filter(item=>item.subjectId  == id)
                console.log("filter Banner ==>",filter);
                if(filter?.length > 0){
                 setBanner(filter[0])
                }
            }
        }).catch(e => {
            setIsLoading(false)
            console.log("error",e)
        })
    }


    const getPapersList = (data) => {
        setIsLoading(true)
        httpService.call(api.getPapers(data.skip, data.take), data).then(res => {

            if (res.data.succeeded) {
                setCount(res.data.data.totalCount)
                setPapers(res.data.data.papersViews.$values);
                if (res.data.data.papersViews.$values) {
                    setIsLoading(false)
                }
            }
        }).catch(e => {
            setIsLoading(false)
            console.log(e)
        })
    }
    const getPapersListBySubjectId = () => {
        setIsLoading(true)
        httpService.call(api.getPapersBySubjectId(id)).then(res => {
            if (res.data.succeeded) {
                let arr = [];
                res.data.data.$values.map(e => {
                    e.papersList.$values.map(res => {
                        arr.push({ ...res, month: e.monthName, year: e.yearName });
                    });
                })
                console.log("array",arr);
                setPapers(papers.concat(arr))
                setIsLoading(false)
            }
        }).catch(e => {
            setIsLoading(false)
            console.log(e)
        })
    }

    const filterMonthYear = value => {
        if (month && year) {
            if (
                value.month == month &&
                value.year == year
            ) {
                return value;
            }
        } else if (month && !year) {
            if (value.month == month) {
                return value;
            }
        } else if (!month && year) {
            if (value.year == year) {
                return value;
            }
        } else {
            return value;
        }
    };

    const filterPaperType = value => {
        if (paperType) {
            if (value.paperType.toLowerCase() === paperType.toLowerCase()) {
                return value
            }
        } else {
            return value;
        }
    }
    const filterSearch = value => {
        if (search) {
            if (value?.questionName?.toLowerCase()?.includes(search) || value?.answerName?.toLowerCase()?.includes(search)) {
                return value
            }
        } else {
            return value;
        }
    }

    const paperData = () =>
        papers.filter(
            e => filterMonthYear(e) && filterPaperType(e) && filterSearch(e),
        );


    const handleSelectedPdf = (url) => {
        if (!selectedPDF.includes(url)) {
            selectedPDF.push(url)
        } else {
            let index = selectedPDF.indexOf(url)
            selectedPDF.splice(index, 1)
        }
        console.log(selectedPDF)
        setSelectedPDF(selectedPDF)
    }

    const handleChange = (url) => {
        if (selectedPDF.includes(url)) {
            setSelectedPDF(selectedPDF.filter(item => item !== url));
        } else {
            setSelectedPDF([...selectedPDF, url]);
        }
    };

    const multiPdfDownload = () => {
        console.log(selectedPDF)
        if (selectedPDF.length > 0) {
            downloadPDFsAsZip(selectedPDF,setIsMultipleLoading)
            setSelectedPDF([])
        }
    }

    return (
        <Box className="main-container">

{banner && <Box  style={{ width:'100%', height:'20vh' }}>
                        <img src={banner?.image} width={"100%"} height={"100%"} style={{objectFit:'cover'}} />
                    </Box>
}
            <Typography variant="h1" className="heading">Past Paper</Typography>
            <FilterBar
                setSearch={setSearch}
                setYear={setYear}
                setMonth={setMonth}
                setPaperType={setPaperType}
            />
            {isLoading ? (
                <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', height: '60vh' }}>
                    <CircularProgress size={50} sx={{ margin: 5 }} />
                    <Typography component="h1" variant="h3">
                        Loading....
                    </Typography>
                </Box>
            ) : <Table papers={paperData()} handleSelectedPdf={handleChange} selectedPDF={selectedPDF} />}
            {!id && count > 10 && <Pagination
                count={count}
                page={page}
                onChange={(value) => {
                    setPage(value)
                    const params = {};
                    params.skip = (value - 1) * 10;
                    params.take = 10;
                    getPapersList(params)
                }} />}
            {selectedPDF.length > 0 && <Box className='bottomBar'>
                <CustomButton onClick={multiPdfDownload} text="Multiple Download" color="green"  isloading={isMultipleLoading}/>
            </Box>}
        </Box>
    )

}
export default PaperModel;