import React, { useState, useEffect } from 'react';
import { Box, Typography, Autocomplete, TextField, Button, CircularProgress, MenuItem, Grid, Backdrop, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { api } from '../../interfaces/api.interface';
import httpService from '../../service/http.service';
import { useNavigate } from 'react-router-dom';

const CourseSelection = ({ navigationHandler, isTeacher,open }) => {
  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [levels, setLevels] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [selectedSubjectsByLevel, setSelectedSubjectsByLevel] = useState({});
  const [experience, setExperience] = useState(null);
  const [errors, setErrors] = useState({});

  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  const handleSaveSubjects = (selectedSubjects, levelId) => {
    setSelectedSubjectsByLevel({
      ...selectedSubjectsByLevel,
      [levelId]: selectedSubjects
    });
    setOpenModal(false); // Close the modal after saving subjects
  };

  const getPrograms = () => {
    httpService.call(api.getPrograms())
      .then(res => {
        if (res.status === 200) {
          let _data = res.data.data["$values"];
          _data = _data.map(item => ({ id: item.id, title: item.programName }));
          setPrograms(_data);
        }
      }).catch(e => console.log(e));
  };

  const getLevels = (programId) => {
    httpService.call(api.getLevelsByProgramId(programId))
      .then(res => {
        if (res.status === 200) {
          let _data = res.data.data["$values"];
          _data = _data.map(item => ({ id: item.id, title: item.levelName }));
          setLevels(_data);
        }
      }).catch(e => console.log(e));
  };

  const getSubjects = (levelIds) => {
    httpService.call(api.getSubjectsByLevelId(levelIds))
      .then(res => {
        if (res.status === 200) {
          let _data = res.data.data["$values"];
          _data = _data.map(item => ({ id: item.id, title: item.subjectName }));
          setSubjects(_data);
        }
      }).catch(e => console.log(e));
  };

  const postStudentCourse = (data) => {
    setLoading(true);
    httpService.call(api.studentCourse(), data, true)
      .then(res => {
        if (res.data.succeeded) {
          clear();
          navigate("/studentDashboard")        
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        console.log("error", e);
      });
  };

  useEffect(() => {
    getPrograms();
  }, []);

  useEffect(() => {
    if (selectedProgram) {
      getLevels(selectedProgram.id);
      setSelectedLevels([]);
      setSelectedSubjectsByLevel({});
    } else {
      setLevels([]);
      setSelectedSubjectsByLevel({});
    }
  }, [selectedProgram]);

  useEffect(() => {
    if (selectedLevels.length > 0) {
      getSubjects(selectedLevels.map(level => level.id));
    } else {
      setSubjects([]);
    }
  }, [selectedLevels]);

  const validate = () => {
    const newErrors = {};
    if (!selectedProgram) newErrors.program = 'Program is required';
    if (selectedLevels.length === 0) newErrors.levels = 'At least one level is required';
    if (selectedLevels.some(level => !selectedSubjectsByLevel[level.id]?.length)) {
      newErrors.subjects = 'Select subjects for all levels';
    }
    if (isTeacher && !experience) newErrors.experience = 'Experience is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validate()) return;

    const state = {
      programId: selectedProgram?.id,
      studentLevels: selectedLevels.map(level => ({
        levelId: level.id,
        studentSubjects: selectedSubjectsByLevel[level.id].map(subject => ({
          subjectId: subject.id
        }))
      }))
    };

    postStudentCourse(state);
  };

  const clear = () => {
    setSelectedProgram(null);
    setSelectedLevels([]);
    setSelectedSubjectsByLevel({});
    setExperience(null);
    setErrors({});
  };

  return (
      <Dialog open={open} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Select Courses</Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{ color: (theme) => theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <form onSubmit={handleSubmit} >
              <Grid container spacing={2}>
                {isTeacher && (
                  <Grid item xs={12}>
                    <TextField
                      select
                      variant="outlined"
                      value={experience}
                      onChange={(e) => setExperience(e.target.value)}
                      fullWidth
                      margin='normal'
                      label="Years of Experience"
                      SelectProps={{ MenuProps: { PaperProps: { style: { maxHeight: 250, width: 200 } } } }}
                      error={!!errors.experience}
                      helperText={errors.experience}
                    >
                      {[...Array(10).keys()].map(item => (
                        <MenuItem key={item + 1} value={item + 1}>{item + 1}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Autocomplete
                    options={programs}
                    getOptionLabel={(option) => option.title}
                    value={selectedProgram}
                    onChange={(event, newValue) => setSelectedProgram(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Program"
                        placeholder="Programs"
                        fullWidth
                        margin="normal"
                        error={!!errors.program}
                        helperText={errors.program}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    options={levels}
                    getOptionLabel={(option) => option.title}
                    value={selectedLevels}
                    onChange={(event, newValue) => setSelectedLevels(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Levels"
                        placeholder="Levels"
                        fullWidth
                        margin="normal"
                        error={!!errors.levels}
                        helperText={errors.levels}
                      />
                    )}
                  />
                </Grid>
                {selectedLevels.map(level => (
                  <Grid item xs={12} key={level.id}>
                    <Autocomplete
                      multiple
                      options={subjects}
                      getOptionLabel={(option) => option.title}
                      value={selectedSubjectsByLevel[level.id] || []}
                      onChange={(event, newValue) => setSelectedSubjectsByLevel({
                        ...selectedSubjectsByLevel,
                        [level.id]: newValue
                      })}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={`Select Subjects for ${level.title}`}
                          placeholder="Subjects"
                          fullWidth
                          margin="normal"
                          error={!!errors.subjects && !selectedSubjectsByLevel[level.id]?.length}
                          helperText={errors.subjects && !selectedSubjectsByLevel[level.id]?.length ? errors.subjects : null}
                        />
                      )}
                    />
                  </Grid>
                ))}
              </Grid>
            </form>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default CourseSelection;
