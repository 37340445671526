import React,{useState,useEffect} from 'react'
import { Box, Typography, Avatar } from '@mui/material';
import "./StudentMaterial.scss";
import { api } from "../../interfaces/api.interface";
import httpService from "../../service/http.service";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PaperModel from '../paperModel/paperModel';
import StudentNotes from './StudentNotes';
import StudentVideos from './StudentVideos';
import { useLocation } from 'react-router-dom';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  
function StudentMaterial() {
  
  const [value, setValue] = React.useState(0);
  const [allVideos,setAllVideos] = useState([]);
  const [allNotes,setAllNotes] = useState([]);

  let location = useLocation();
  let key = location.state;
  console.log(key);

  const getAllNotes = () => {
    httpService.call(
      api.getAllNotes(),null,true
    ).then(res => {
      if (res?.status === 200) {
        setAllNotes(res.data.data.teachersViews["$values"])
        console.log("Levels====", res.data.data.teachersViews["$values"])
      }
    }).catch((e) => {
      console.log(e)
    })
  }
 
  
  const getAllVideos = () => {
    httpService.call(
      api.getAllVideos(),null,true
    ).then(res => {
      if (res?.status === 200) {
        setAllVideos(res.data.data.teachersViews["$values"])
        console.log("Videos====", res.data.data.teachersViews["$values"])
      }
    }).catch((e) => {
      console.log(e)
    })
  }
 

  useEffect(()=> {
    getAllNotes();
    getAllVideos();
  },[]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box class={'material-top-container'}>
      {/* <Box className='profile-container'>
       <Avatar sx={{ width: '100px', height: '100px',border:4,borderColor:'white'}}  src='https://images.pexels.com/photos/8617636/pexels-photo-8617636.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500' />
       <Typography variant='h2'  sx={{ fontSize:{lg:24,sm:18,xs:16,}}}>{teacherData.teacherName}</Typography>
                        <Typography variant='h6'  sx={{ fontSize:{lg:16,sm:12,xs:8,color:'#aaa'}}}> {teacherData.yearOfExp} of experience</Typography>
                        <Typography variant='h6'  sx={{ fontSize:{lg:16,sm:12,xs:8,color:'#03494b'},fontWeight:'bold'}}>{teacherData.subjectSpec ?? 'Physics'}</Typography>
                        
          </Box> */}
          {/* <Box>
        <Tabs 
        TabIndicatorProps={{
            style: {
              backgroundColor: "#03494B",
              color: "#03494B",
            }
          }}
          textColor="#03494B"
          centered
          
          sx={{ display:'flex',alignItems:'center',justifyContent:'center'}}
        value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Notes" style={{fontSize:18}} {...a11yProps(0)} />
          <Tab label="Videos" style={{fontSize:18}} {...a11yProps(1)} />
        </Tabs>
      
      <CustomTabPanel value={value} index={0}> */}
      {key == "Notes" ? <StudentNotes/> : <StudentVideos/>}
      {/* </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
          <StudentVideos/>
      </CustomTabPanel>      
    </Box> */}
    </Box>
  )
}

export default StudentMaterial;