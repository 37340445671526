import React from 'react';
import { Modal, Box, Typography, Button, Stack } from '@mui/material';
import { useNavigate } from "react-router-dom";

const LoginPromptModal = ({ open, handleClose }) => {
  const navigate = useNavigate();

  const handleLogin = () => {
    // Navigate to login page or handle login logic here
    navigate('/login');    
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-login-title"
      aria-describedby="modal-login-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-login-title" variant="h6" component="h2" sx={{ mb: 2 }}>
          Login Required
        </Typography>
        <Typography id="modal-login-description" sx={{ mb: 3 }}>
          You need to log in to access notes and videos.
        </Typography>
        <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogin}
            sx={{
              flex: 1,
              backgroundColor: '#03494b',
              '&:hover': { backgroundColor: '#03494b' }
            }}
          >
            Login
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClose}
            sx={{
              flex: 1,
              backgroundColor: 'grey',
              '&:hover': { backgroundColor: 'darkgrey' }
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default LoginPromptModal;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};
