import React, { useState, useEffect } from 'react';
import './teacherDashboard.scss';


//MUI Imports
import {Typography,Box} from '@mui/material';
import CustomizedTable from '../../component/table/customizedTable';


//Verrtical Tabs
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import httpService from '../../service/http.service';
import { api } from '../../interfaces/api.interface';
import { renderActionsCell, renderBooleanCell } from '@mui/x-data-grid';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



 function DataTabs() {
  const [value, setValue] = React.useState(0);
  const [allVideos,setAllVideos] = useState([]);
  const [allNotes,setAllNotes] = useState([]);


  const getAllNotes = () => {
    httpService.call(
      api.getAllNotes(),null,true
    ).then(res => {
      if (res?.status === 200) {
        setAllNotes(res.data.data.teachersViews["$values"])
        console.log("Levels====", res.data.data.teachersViews["$values"])
      }
    }).catch((e) => {
      console.log(e)
    })
  }
 
  
  const getAllVideos = () => {
    httpService.call(
      api.getAllVideos(),null,true
    ).then(res => {
      if (res?.status === 200) {
        setAllVideos(res.data.data.teachersViews["$values"])
        console.log("Videos====", res.data.data.teachersViews["$values"])
      }
    }).catch((e) => {
      console.log(e)
    })
  }
 

  useEffect(()=> {
    getAllNotes();
    getAllVideos();
  },[]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const genericColumn = [
    { field: 'programName', headerName: 'Program', minWidth: 100,flex:1  },
    { field: 'levelName', headerName: 'Level', minWidth: 80},
    { field: 'subjectName', headerName: 'Subject', minWidth: 80,flex:1},
    { field: 'monthName', headerName: 'Month', minWidth: 80},
    { field: 'yearName', headerName: 'Year', minWidth: 80},
  ]
  const videColumns = [
    ...genericColumn,
    { field: 'videoName', headerName: 'Video', minWidth: 100},
    { field: 'videoUrl', headerName: 'URL', minWidth: 100,flex:1  },
    { field: 'isApproved', headerName: 'Approved', minWidth: 80, align:'center', renderCell:(params)=> {
    return params.value  ? <CheckCircleIcon style={{color:'green'}}/> :<WatchLaterIcon style={{color:'grey'}}/>}}
  ];

  const notesColumns = [
    ...genericColumn,
    { field: 'notesName', headerName: 'Note', minWidth: 100 },
    { field: 'notesPdf', headerName: 'PDF', minWidth: 100,flex:1 },
    { field: 'isApproved', headerName: 'Approved', minWidth: 80,align:'center',renderCell:(params)=> {
      return params.value  ? <><CheckCircleIcon style={{color:'green'}}/></> :<><WatchLaterIcon style={{color:'grey'}}/></>}}
  
  ]

  return (
    <Box>
      <Box>
        <Tabs 
        TabIndicatorProps={{
            style: {
              backgroundColor: "#03494B",
              color: "#03494B",
            }
          }}
          textColor="#03494B"
          centered
          
          sx={{ display:'flex',alignItems:'center',justifyContent:'center'}}
        value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Notes" style={{fontSize:18}} {...a11yProps(0)} />
          <Tab label="Videos" style={{fontSize:18}} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <CustomizedTable data={allNotes} columns={notesColumns}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <CustomizedTable data={allVideos} columns={videColumns}/>

      </CustomTabPanel>      
    </Box>
  );
}

const DashboardMainScreen  = ()=> {


  return(
    <DataTabs/>
  )
}

export default DashboardMainScreen;
