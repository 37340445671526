import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import Cookies from "js-cookie";
import LockResetIcon from "@mui/icons-material/LockReset";
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from "react-router-dom";
import httpService from "../../service/http.service";
import { api } from "../../interfaces/api.interface";

export default function ProfileMenu() {
 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [user, setUser] = useState(null);

  const role = localStorage.getItem('role');
  
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserProfile = () => {
    httpService
      .call(api.getUserProfile(), null, true)
      .then((res) => {
        console.log("User Profile =====>", res.data);
        const { succeeded, data } = res?.data;

        if (succeeded) {
          // localStorage.setItem('user',JSON.stringify(data));
          setUser(data);
        }
      })
      .catch((e) => {
        // setIsLoading(false);
        console.log(e);
      });
  };

  const hnadleLogout = () => {
    Cookies.remove("token");
    localStorage.removeItem("role");
    handleClose();
    navigate("/login");
  };

  const handleProfile = () => navigate("/profileView");
  const handleStudentProfile = () => navigate("/StudentProfileView");
  const handleChangePassword = () => navigate("/changePassword");

  useEffect(() => {
    getUserProfile();
  }, []);



  console.log("userRole ==>",role);
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography>
        <Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 36, height: 36, backgroundColor: "#03494B",marginRight:1 }}>
            </Avatar>
        <Typography variant='h3' style={{color:'#FFF'}}>{user?.firstName}</Typography>
          </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              width: 220,
              height: 130,
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={role == "Teacher" ? handleProfile :  handleStudentProfile}>
        <ListItemIcon>
          <PersonIcon fontSize="medium" color="grey" />
          </ListItemIcon>
          Profile
        </MenuItem>
        <MenuItem onClick={handleChangePassword}>
          <ListItemIcon>
            <LockResetIcon fontSize="medium" />
          </ListItemIcon>
          Change Password
        </MenuItem>
        <MenuItem onClick={hnadleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
