import { publicRoutes } from "./public.js";
import MainLayout from "../layout/MainLayout.jsx";
import NotFound from "../layout/NotFound.jsx";
import RequireAuth from "../layout/RequireAuth.jsx";
import { privateRoutes} from './private.js';

const routes = [
  {
    path: "/",
    element: <MainLayout />,
    children: publicRoutes,
  },
  {
    path: "/",
    element: <MainLayout />,
    children: privateRoutes,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
