import React, { useState, useEffect } from 'react';
import './teacherDashboard.scss';


//MUI Imports
import { TextField, Button, Typography, InputAdornment, Box, Autocomplete, Grid } from '@mui/material';


//Local Imports
import TabPanel from '../../component/tabs/tabPanel';
import CustomTabPanel from '../../component/tabs/customTabPanels';

//Verrtical Tabs
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import httpService from '../../service/http.service';
import { api } from '../../interfaces/api.interface';
import CustomizedTable from '../../component/table/customizedTable';
import DashboardMainScreen from './dashboardMainScreen';



export default function TeacherDashboard() {

  const [value, setValue] = React.useState(1);

  const notesURL = `https://serapi.serprep.com/api/TeachersMaterial/PostNotes`;
  const videoURL = `https://serapi.serprep.com/api/TeachersMaterial/PostVideos`;

  const handleChange = (event, newValue) => {
    console.log(newValue)
    setValue(newValue);
  };

  //Vertical Tabs
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  return(

    <div className='main'>
      <Box
        sx={{ maxWidth: '80%', margin: '0px auto', bgcolor: 'background.paper', display: 'flex', minHeight: '85vh' }}
      >

        {/* Tabs */}
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#03494B",
              color: "#03494B"
            }
          }}
          textColor="#03494B"
          sx={{ borderRight: 1, borderColor: 'divider', width: '20%' }}
        >
          <Typography style={{ alignSelf: 'center', fontWeight: 'bold', fontSize: 24, margin: 16, color: '#03494B' }} className={"heading"}>
            Welcome
          </Typography>

          <Tab label="Dashboard" style={{ fontSize: 12 }} {...a11yProps(1)} />
          <Tab label="Notes" style={{ fontSize: 12 }} {...a11yProps(2)} />
          <Tab label="Videos" style={{ fontSize: 12 }} {...a11yProps(3)} />
        </Tabs>

        {/* Tab Panels */}
        <TabPanel value={value} index={1} style={{ width: '80%', margin: '20px auto' }}>
          <DashboardMainScreen/>
        </TabPanel>

        <TabPanel value={value} index={2} style={{ width: '50%', margin: '50px auto' }}>
          <CustomTabPanel heading={'Notes'} URL={notesURL}/>
        </TabPanel>

        <TabPanel value={value} index={3} style={{ width: '50%', margin: '50px auto' }}>
           <CustomTabPanel heading={'Videos'} URL={videoURL}/>
        </TabPanel>
        
        <Box >
        </Box>
      </Box>
    </div>

  )

}