import React from 'react'
import { Box,Typography,Grid } from '@mui/material';
import CustomButton from '../button';
import Info from "../../assets/information.png";
import './teacherProfileCard.scss';

export default function TeacherProfileCard({image,name,subject,experience,education,onClick}) {
  const  dummyImage = "https://images.pexels.com/photos/8617636/pexels-photo-8617636.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
  return (
    <Box className='card-container'>
        <Box className='.profile-image-conatiner'>
        <img src={image ? image : dummyImage} alt="Dinosaur"  className='teacher-image'/>    
        </Box>
        <Box className='left-align'>
            <Box className='profile-text-conatiner'>
                <Typography variant='h2' style={{}}>{`${name}`}</Typography>
                <Typography variant='body2' style={{color:'grey'}}>{`Subject Specialist  ${subject}`}</Typography>
                <Typography variant='body2' style={{color:'grey'}}>{`Experience of ${experience}`}</Typography>
                <Typography variant='body2' style={{color:'grey'}}>{`Education ${education ? education : ""}`}</Typography>
            </Box>
            <CustomButton onClick={onClick} text="Video/Notes" color="green"  className={'profile-btn'} borderColor={'#ddd'}
                icon={Info}
                />
            </Box>
    </Box>
  )
}
