import { Box, CircularProgress, Grid, Menu,Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import './menuBar.scss'
import Subjects from '../../assets/subjects.png';
import SearchBar from "../searchBar/searchBar";
import httpService from "../../service/http.service";
import { api } from "../../interfaces/api.interface";
import { useNavigate } from "react-router-dom";

const SubjectMenuBar = (props) => {
    const { anchorEl, setAnchorEl, open, menuToggleHandler } = props;


    const [subjects, setSubjects] = useState([])
    const [isSubjectLoading, setIsSubjectLoading] = useState(false)

    const [search, setSearch] = useState('')

    const navigate = useNavigate();

    useEffect(() => {
        if (open) {
            getAllSubjects()
        }
    }, [open])

    const getAllSubjects = () => {
        setIsSubjectLoading(true)
        httpService.call(api.getSubjects()).then(res => {
            console.log(res?.data?.data?.$values.length)
            if (res.data.succeeded) {
                let data = res?.data?.data?.$values;
                setSubjects(data);
                setIsSubjectLoading(false)
            }
        }).catch(e => {
            setIsSubjectLoading(false)
            console.log(e)
        })
    }

    const handleClose = () => {
        setAnchorEl(null)
        setSubjects([])
    }

    const selectSubject = (id) => {
        navigate(`subject/${id}`)
        handleClose()
        if(menuToggleHandler){
            menuToggleHandler()
        }
    }

    return (
        <Menu
            id="basic-menu"
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            classes={{ list: "header-person-details-list" }}
            anchorEl={anchorEl}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            open={open}
            onClose={!isSubjectLoading && handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            marginThreshold={50}
        >

            <Box className='boxContainer'>
                <Grid container >
                    <Grid item xs={12}>
                        <Box className='subjectContainer'>
                                    <Box className='subjectHeader'>
                                        <SearchBar placeholder={'Search'} handleChange={setSearch} />
                                    </Box>

                                    {
                                        isSubjectLoading ? <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <CircularProgress size={50} sx={{ margin: 5 }} />
                                        <Typography variant="body1">
                                            Loading....
                                        </Typography>
                                    </Box> :
                                    
                                    <Box className='subjectList'>
                                        {
                                            subjects?.filter((e, i) => e.subjectName.toLowerCase().includes(search.toLowerCase())).map((e, i) => (
                                                <Box className='subjectItems' onClick={() => selectSubject(e.id)}>
                                                    <img src={Subjects} />
                                                    <Typography variant="h3">{e.subjectName}</Typography>
                                                </Box>
                                            ))
                                        }
                                    </Box>
}
                            </Box>
                    </Grid>
                </Grid>
            </Box>
        </Menu>
    )
};

export default memo(SubjectMenuBar);
