import React, { useState } from 'react';
import { Modal, Box, TextField, Button, FormControl, InputLabel, Select, MenuItem, Typography,Grid } from '@mui/material';

const NeedATutorModal = ({ open, handleClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    program: '',
    level: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Check if all fields are filled
    const isValid = Object.values(formData).every(value => value.trim() !== '');
    
    if (isValid) {
      // Handle form submission logic here
      console.log(formData);
      handleClose(); // Close the modal after submission
    } else {
      alert('Please fill out all fields.');
    }
  };
const style = {
  borderRadius:3,
'& .MuiOutlinedInput-root': {
  borderRadius: '30px',
  padding: '10px',
  '& fieldset': {
    borderColor: 'transparent',
  },
  '&:hover fieldset': {
    borderColor: 'transparent',
  },
  '&.Mui-focused fieldset': {
    borderColor: 'transparent',
  },
  '& input': {
    padding: '10px',
  },
},
'& .MuiInputBase-input::placeholder': {
  color: 'grey',
},
}
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            height: '70vh',  // Set a fixed height for the modal
            maxHeight: '80vh', // Optional: max height to ensure it doesn't exceed the viewport
            bgcolor: 'background.paper',
            border: '1px solid #000',
            boxShadow: 24,
            p: 4,
            overflow: 'auto', // Enable scrolling if content overflows
        }}
      >
        <form onSubmit={handleSubmit}>
            <Typography varient="h2">Fill the following fields:</Typography>
            <Grid conatiner>
            <Grid item xs={6} sm={6}  md={6}>
          <TextField
            label="Name"
            name="name"
            variant="outlined"
            // fullWidth
            margin="normal"
            value={formData.name}
            onChange={handleChange}
            required
            sx={{...style}}
          />
          </Grid>

          <Grid item xs={6} sm={6}  md={6}>

          <TextField
            label="Email"
            name="email"
            type="email"
            variant="outlined"
            // fullWidth
            margin="normal"
            value={formData.email}
            onChange={handleChange}
            required
            sx={{...style}}

          />
        </Grid>

          <TextField
            label="Phone Number"
            name="phone"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.phone}
            onChange={handleChange}
            required
            sx={{...style}}

          />
            <TextField
            label="Program"
            name="Program"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.program}
            onChange={handleChange}
            sx={{...style}}
            required
          />
            <TextField
            label="Level"
            name="Level"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.level}
            onChange={handleChange}
            required
            sx={{...style}}
          />
          <TextField
            label="Subject"
            name="subject"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.subject}
            onChange={handleChange}
            required
            sx={{...style}}
          />
          <TextField
            label="Message"
            name="message"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={formData.message}
            onChange={handleChange}
            required
            sx={{...style}}
          />
          </Grid>
            <Button
            type="submit"
            variant="contained"
            sx={{ mt: 2,backgroundColor:'#03494b' }}
          >
            Submit
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default NeedATutorModal;
