import React, { useState } from "react";
import { TextField, Typography, InputAdornment, Box } from "@mui/material";

import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// Icons
import MailIcon from "@mui/icons-material/Mail";
import LockIcon from "@mui/icons-material/Lock";
import IconButton from "@mui/material/Button";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import GradientButton from "../../component/gradientButton";
import httpService from "../../service/http.service";
import { api } from "../../interfaces/api.interface";
// import "./ChangePassword.scss";

const ChangePassword = () => {
 
  // states
  const [isLoading, setIsLoading] = useState(false);
  
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState({ isError: false, message: "" });

  const navigate = useNavigate();

  const toggleShowPassword = () => setShowPassword((show) => !show);
  const toggleShowNewPassword = () => setShowNewPassword((show) => !show);

  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const clearFields = () => {
    setPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setApiError({ isError: false, message: "" });
  };

  const handleChangePassword = () => {
    const data = {
      password: password,
      newPassword:newPassword,
      confirmNewPassword: confirmPassword,
    };

    console.log(data);
    setIsLoading(true);

    httpService
      .call(api.changePassword(), data,true)
      .then((res) => {
        console.log("data====>", res?.data);
        if(res?.status ==  200 && res?.data?.succeeded){
          setIsLoading(false);
          clearFields();
          navigate("/login");
        }
       else {
        return Promise.reject(res)
      }
      setIsLoading(false);
  })
      .catch((err) => {
        setApiError({ isError: true, message: err?.data?.messages?.$values[0] }); 
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateData();
  };

  const validateData = () => {
    const newErrors = {};

    if (!password) {
      newErrors.password = "Password is required";
    } else if (password.length < 8) {
      newErrors.password = "Password must be at least of length 8";
    } else if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/.test(password)) {
      newErrors.password =
        "Your password must contain at least one uppercase letter, one lowercase letter, and one number.";
    }
    
    if (!newPassword) {
      newErrors.newPassword = "New Password is required";
    } else if (newPassword.length < 8) {
      newErrors.newPassword = "Password must be at least of length 8";
    } else if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/.test(newPassword)) {
      newErrors.newPassword =
        "Your password must contain at least one uppercase letter, one lowercase letter, and one number.";
    }
    if (newPassword !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      handleChangePassword();
    }
  };

  return (
    <Box style={{ width: "100%" }}>
      <Box className="container">
        <div className="root">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {apiError.isError && (
            <Alert variant="outlined" severity="error">
              {apiError.message}
            </Alert>
          )}

          <form className={"form"} onSubmit={handleSubmit}>
            <Typography variant="h1" style={{ fontSize: 48, marginBottom: 20 }}>
              Change Password
            </Typography>

            <TextField
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: "#03494B" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff sx={{ color: "grey" }} />
                      ) : (
                        <Visibility sx={{ color: "grey" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              margin="normal"
              error={!!errors.password}
              helperText={errors.password}
            />

<TextField
              placeholder="New Password"
              type={showNewPassword ? "text" : "password"}
              variant="outlined"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: "#03494B" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={toggleShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showNewPassword ? (
                        <VisibilityOff sx={{ color: "grey" }} />
                      ) : (
                        <Visibility sx={{ color: "grey" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}

              fullWidth
              margin="normal"
              error={!!errors.newPassword}
              helperText={errors.newPassword}
            />


            <TextField
              placeholder="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: "#03494B" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={toggleShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <VisibilityOff sx={{ color: "grey" }} />
                      ) : (
                        <Visibility sx={{ color: "grey" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              margin="normal"
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
            />

            <GradientButton onClick={handleSubmit}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Change Password
              </Typography>
            </GradientButton>
          </form>
        </div>
      </Box>
    </Box>
  );
};

export default ChangePassword;
