import React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";

// Define a styled component with gradient text color

const GradientText = styled(Typography)({
  background: "-webkit-linear-gradient(90deg, #080808, #03494b)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontWeight: "600",
  marginTop:6
});

const GradientTextComponent = ({ children }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(min-width:601px) and (max-width: 960px)");
  const isDesktop = useMediaQuery("(min-width:961px)");

  return (
    <div>
      {/* Apply the GradientText component */}
      <GradientText variant={"body2"}>{children}</GradientText>
    </div>
  );
};

export default GradientTextComponent;
