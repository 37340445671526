import React, { useState, useEffect } from 'react';
import { Box, Typography, Autocomplete, TextField, Button, CircularProgress, MenuItem, Grid } from '@mui/material';
import { api } from '../../interfaces/api.interface';
import httpService from '../../service/http.service';
import SubjectSelectionModal from '../../component/modal/SubjectSeletionModal';

const Exam = ({ navigationHandler, isTeacher }) => {
  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [levels, setLevels] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [selectedSubjectsByLevel, setSelectedSubjectsByLevel] = useState({});
  const [experience, setExperience] = useState(null);
  const [errors, setErrors] = useState({});

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSaveSubjects = (selectedSubjects, levelId) => {
    setSelectedSubjectsByLevel({
      ...selectedSubjectsByLevel,
      [levelId]: selectedSubjects
    });
    setOpenModal(false); // Close the modal after saving subjects
  };

  const getPrograms = () => {
    httpService.call(api.getPrograms())
      .then(res => {
        if (res.status === 200) {
          let _data = res.data.data["$values"];
          _data = _data.map(item => ({ id: item.id, title: item.programName }));
          setPrograms(_data);
        }
      }).catch(e => console.log(e));
  };

  const getLevels = (programId) => {
    httpService.call(api.getLevelsByProgramId(programId))
      .then(res => {
        if (res.status === 200) {
          let _data = res.data.data["$values"];
          _data = _data.map(item => ({ id: item.id, title: item.levelName }));
          setLevels(_data);
        }
      }).catch(e => console.log(e));
  };

  const getSubjects = (levelIds) => {
    httpService.call(api.getSubjectsByLevelId(levelIds))
      .then(res => {
        if (res.status === 200) {
          let _data = res.data.data["$values"];
          _data = _data.map(item => ({ id: item.id, title: item.subjectName }));
          setSubjects(_data);
        }
      }).catch(e => console.log(e));
  };

  const postStudentCourse = (data) => {
    setLoading(true);
    httpService.call(api.studentCourse(), data, true)
      .then(res => {
        if (res.data.succeeded) {
          clear();
          navigationHandler(null,0)
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        console.log("error", e);
      });
  };

  useEffect(() => {
    getPrograms();
  }, []);

  useEffect(() => {
    if (selectedProgram) {
      getLevels(selectedProgram.id);
      setSelectedLevels([]);
      setSelectedSubjectsByLevel({});
    } else {
      setLevels([]);
      setSelectedSubjectsByLevel({});
    }
  }, [selectedProgram]);

  useEffect(() => {
    if (selectedLevels.length > 0) {
      getSubjects(selectedLevels.map(level => level.id));
    } else {
      setSubjects([]);
    }
  }, [selectedLevels]);

  const validate = () => {
    const newErrors = {};
    if (!selectedProgram) newErrors.program = 'Program is required';
    if (selectedLevels.length === 0) newErrors.levels = 'At least one level is required';
    if (selectedLevels.some(level => !selectedSubjectsByLevel[level.id]?.length)) {
      newErrors.subjects = 'Select subjects for all levels';
    }
    if (isTeacher && !experience) newErrors.experience = 'Experience is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validate()) return;

    const state = {
      programId: selectedProgram?.id,
      studentLevels: selectedLevels.map(level => ({
        levelId: level.id,
        studentSubjects: selectedSubjectsByLevel[level.id].map(subject => ({
          subjectId: subject.id
        }))
      }))
    };

    postStudentCourse(state);
  };

  const clear = () => {
    setSelectedProgram(null);
    setSelectedLevels([]);
    setSelectedSubjectsByLevel({});
    setExperience(null);
    setErrors({});
  };

  return (
    <Box sx={screenStyle}>
      {loading ? (
        <CircularProgress sx={{ color: '#03494b' }} />
      ) : (
        <Box sx={{ width: '100%', maxHeight: '70vh', overflow: 'auto' }}>
          <form style={{ width: '100%' }}>
            <Grid container spacing={2}>
              {isTeacher && (
                <Grid item xs={12}>
                  <TextField
                    select
                    variant="outlined"
                    value={experience}
                    onChange={(e) => setExperience(e.target.value)}
                    fullWidth
                    margin='normal'
                    label="Years of Experience"
                    SelectProps={{ MenuProps: { style: { height: 250, maxWidth: 200 } } }}
                    error={!!errors.experience}
                    helperText={errors.experience}
                  >
                    {[...Array(10).keys()].map(item => (
                      <MenuItem key={item + 1} value={item + 1}>{item + 1}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
              <Grid item xs={12}>
                <Autocomplete
                  options={programs}
                  getOptionLabel={(option) => option.title}
                  value={selectedProgram}
                  onChange={(event, newValue) => setSelectedProgram(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Program"
                      placeholder="Programs"
                      fullWidth
                      margin="normal"
                      error={!!errors.program}
                      helperText={errors.program}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={levels}
                  getOptionLabel={(option) => option.title}
                  value={selectedLevels}
                  onChange={(event, newValue) => setSelectedLevels(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Levels"
                      placeholder="Levels"
                      fullWidth
                      margin="normal"
                      error={!!errors.levels}
                      helperText={errors.levels}
                    />
                  )}
                />
              </Grid>
              {selectedLevels.map(level => (
                <Grid item xs={12} key={level.id}>
                  <Autocomplete
                    multiple
                    options={subjects}
                    getOptionLabel={(option) => option.title}
                    value={selectedSubjectsByLevel[level.id] || []}
                    onChange={(event, newValue) => setSelectedSubjectsByLevel({
                      ...selectedSubjectsByLevel,
                      [level.id]: newValue
                    })}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={`Select Subjects for ${level.title}`}
                        placeholder="Subjects"
                        fullWidth
                        margin="normal"
                        error={!!errors.subjects && !selectedSubjectsByLevel[level.id]?.length}
                        helperText={errors.subjects && !selectedSubjectsByLevel[level.id]?.length ? errors.subjects : null}
                      />
                    )}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  fullWidth
                  sx={{ backgroundColor: '#03494b', '&:hover': { backgroundColor: '#03494b' } }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}

      {openModal && (
        <SubjectSelectionModal
          subjects={subjects}
          open={openModal}
          onClose={handleCloseModal}
          onSave={(selectedSubjects) => handleSaveSubjects(selectedSubjects)}
        />
      )}
    </Box>
  );
};

const screenStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  minHeight: '50vh',
  bgcolor: 'background.paper',
  p: 4,
  borderRadius: 1,
};

export default Exam;
