import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Autocomplete, TextField, Button, CircularProgress, MenuItem } from '@mui/material';
import { api } from '../../interfaces/api.interface';
import httpService from '../../service/http.service';

const ModalWithCheckboxes = ({ open, handleClose, navigationHandler, isTeacher }) => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [levels, setLevels] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [experience, setExperience] = useState(null);

  const getPrograms = () => {
    setLoading(true);
    httpService.call(api.getPrograms())
      .then(res => {
        if (res.status === 200) {
          let _data = res.data.data["$values"];
          _data = _data.map(item => ({ id: item.id, title: item.programName }));
          setPrograms(_data);
        }
      }).catch(e => console.log(e))
      .finally(() => setLoading(false));
  };

  const getLevels = (programId) => {
    setLoading(true);
    httpService.call(api.getSubjectsByLevelId(programId))
      .then(res => {
        if (res.status === 200) {
          let _data = res.data.data["$values"];
          _data = _data.map(item => ({ id: item.id, title: item.levelName }));
          setLevels(_data);
        }
      }).catch(e => console.log(e))
      .finally(() => setLoading(false));
  };

  const getSubjects = (levelIds) => {
    setLoading(true);
    httpService.call(api.getSubjects())
      .then(res => {
        if (res.status === 200) {
          let _data = res.data.data["$values"];
          _data = _data.map(item => ({ id: item.id, title: item.subjectName }));
          setSubjects(_data);
        }
      }).catch(e => console.log(e))
      .finally(() => setLoading(false));
  };

  const postStudentCourse = (data) => {
    setLoading(true);
    httpService.call(api.studentCourse(), data, true)
      .then(res => {
        if (res.data.succeeded) {
          setLoading(false);
          navigationHandler();
        }
      })
      .catch(e => {
        console.log("error", e);
        setLoading(false);
      });
  };

  useEffect(() => {
    getPrograms();
  }, []);

  useEffect(() => {
    if (selectedProgram) {
      getLevels(selectedProgram.id);
    }
  }, [selectedProgram]);

  useEffect(() => {
    if (selectedLevels.length > 0) {
      getSubjects(selectedLevels.map(level => level.id));
    }
  }, [selectedLevels]);

  const handleSubmit = () => {
    const state = {
      programId: selectedProgram?.id,
      studentLevels: selectedLevels.map(level => ({
        levelId: level.id,
        studentSubjects: selectedSubjects.map(subject => ({ subjectId: subject.id }))
      }))
    };
    postStudentCourse(state);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        {loading ? (
          <CircularProgress sx={{ color: '#03494b' }} />
        ) : (
          <form>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
              Fill in the details
            </Typography>
            {isTeacher && (
              <TextField
                select
                variant="outlined"
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
                fullWidth
                margin='normal'
                label="Years of Experience"
                SelectProps={{ MenuProps: { style: { height: 250, maxWidth: 200 } } }}
              >
                {[...Array(10).keys()].map(item => (
                  <MenuItem key={item + 1} value={item + 1}>{item + 1}</MenuItem>
                ))}
              </TextField>
            )}
            <Autocomplete
              options={programs}
              getOptionLabel={(option) => option.title}
              value={selectedProgram}
              onChange={(event, newValue) => setSelectedProgram(newValue)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Select Program" placeholder="Programs" fullWidth margin="normal" />
              )}
              sx={{ mb: 2 }}
            />
            <Autocomplete
              multiple
              options={levels}
              getOptionLabel={(option) => option.title}
              value={selectedLevels}
              onChange={(event, newValue) => setSelectedLevels(newValue)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Select Levels" placeholder="Levels" fullWidth margin="normal" />
              )}
              sx={{ mb: 2 }}
            />
            <Autocomplete
              multiple
              options={subjects}
              getOptionLabel={(option) => option.title}
              value={selectedSubjects}
              onChange={(event, newValue) => setSelectedSubjects(newValue)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Select Subjects" placeholder="Subjects" fullWidth margin="normal" />
              )}
              sx={{ mb: 2 }}
            />
            <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth sx={{ backgroundColor: '#03494b', '&:hover': { backgroundColor: '#03494b' } }}>
              Submit
            </Button>
          </form>
        )}
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

export default ModalWithCheckboxes;
