import React, { useEffect, useState } from "react";
import "./StudentDashboard.scss";

// MUI Imports
import {
  TextField,
  Button,
  Typography,
  InputAdornment,
  Box,
  Autocomplete,
  Grid,
  Avatar,
} from "@mui/material";

// Local Imports
import TabPanel from "../../../component/tabs/tabPanel";
import CustomTabPanel from "../../../component/tabs/customTabPanels";

// Vertical Tabs
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import httpService from "../../../service/http.service";
import { api } from "../../../interfaces/api.interface";
import CustomizedTable from "../../../component/table/customizedTable";
import Dashboard from "./dashboard";
import Exam from "../Exam";

export default function StudentDashboard() {
  const [value, setValue] = useState(0);
  const [user,setUser] = useState(null);

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  // Vertical Tabs
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const getUserProfile = () => {
    httpService
      .call(api.getUserProfile(), null, true)
      .then((res) => {
        console.log("User Profile =====>", res.data);
        const { succeeded, data } = res?.data;

        if (succeeded) {
          // localStorage.setItem('user',JSON.stringify(data));
          setUser(data);
        }
      })
      .catch((e) => {
        // setIsLoading(false);
        console.log(e);
      });
  };

  useEffect(()=>{
    getUserProfile()
  },[])

  return (
    <div className="main">
      <Box
        sx={{
          maxWidth: "80%",
          margin: "0px auto",
          bgcolor: "background.paper",
          display: "flex",
          minHeight: "85vh",
        }}
      >
        {/* Sidebar with Banner */}
        <Box
          sx={{
            borderRight: 1,
            borderColor: "divider",
            width: "20%",
            backgroundColor: "#03494B",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 2,
              borderBottom: 2,
              borderColor: "white",
            }}
          >
            <Avatar
              alt="Profile Image"
              src={user?.profilePictureDataUrl}
              sx={{ width: 100, height: 100, marginBottom: 1 }}
            />
            <Typography
              style={{ fontWeight: "bold", fontSize: 16, color: "white" }}
            >
              Welcome, {user ?`${user?.firstName}`: 'User' }
            </Typography>
          </Box>

          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#03494B",
                color: "#03494B",
              },
            }}
            textColor="#03494B"
          >
            <Tab
              label="Dashboard"
              style={{ fontSize: 12, color: "white" }}
              {...a11yProps(1)}
            />
            <Tab
              label="Exams"
              style={{ fontSize: 12, color: "white" }}
              {...a11yProps(2)}
            />
            <Tab
              label="Feedback"
              style={{ fontSize: 12, color: "white" }}
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>

        {/* Tab Panels */}
        <TabPanel
          value={value}
          index={0}
          style={{
            width: "80%",
            margin: "0px auto",
            height: "85vh",
            overflow: "hidden",
          }}
        >
          <Dashboard />
        </TabPanel>

        <TabPanel
          value={value}
          index={1}
          style={{
            width: "50%",
            margin: "0px auto",
            height: "85vh",
            // overflow: "hidden",
          }}
        >
          <Exam  navigationHandler={handleChange}/>
        </TabPanel>

        <TabPanel
          value={value}
          index={2}
          style={{ width: "50%", margin: "50px auto" }}
        >
          {/* <CustomTabPanel heading={'Videos'} URL={videoURL}/> */}
        </TabPanel>
      </Box>
    </div>
  );
}
