import React from 'react';
import { Card, CardContent, Grid, Typography, Avatar, Box, Divider, Stack, Chip } from '@mui/material';
import PropTypes from 'prop-types';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import SchoolIcon from '@mui/icons-material/School';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

function ProfileCard({ data }) {
  return (
    <Box className="profile-card" sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              borderRadius: 2,
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            //   p: 2,
            }}
          >
            <Avatar
              variant="square"
              sx={{
                width: 260,
                height: 260,
                borderRadius: 2,
              }}
              src={data?.profilePicture}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
            <Typography variant="h6" gutterBottom>
                {`${data?.title} ${data?.firstName} ${data?.lastName}`}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                <HourglassBottomIcon sx={{ color: 'grey' }} />
                <Typography variant="body1" color="text.secondary">
                  {data?.expYears} Years of experience
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                <LocationCityIcon sx={{ color: 'grey' }} />
                <Typography variant="body1" color="text.secondary">
                  {data?.expIntitution}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <SchoolIcon sx={{ color: 'grey' }} />
                <Typography variant="body1" color="text.secondary">
                  {data?.qualification}
                </Typography>
              </Stack>
            </CardContent>
            <Divider />
            <CardContent>
              <Typography variant="h6" gutterBottom>
                About Me
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {data?.shortIntro}
              </Typography>
            </CardContent>
            <Divider />
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Subjects
              </Typography>
              <Stack direction="row" spacing={1} mt={1} flexWrap="wrap">
                {data?.subjects?.split(',').map((subject, index) => (
                  <Chip key={index} label={subject} />
                ))}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

// ProfileCard.propTypes = {
//   data: PropTypes.shape({
//     profilePicture: PropTypes.string,
//     title: PropTypes.string,
//     firstName: PropTypes.string,
//     lastName: PropTypes.string,
//     expYears: PropTypes.number,
//     expIntitution: PropTypes.string,
//     qualification: PropTypes.string,
//     shortIntro: PropTypes.string,
//     subjects: PropTypes.string,
//   }).isRequired,
// };

export default ProfileCard;
