import React, { useEffect, useState } from "react";
import "./Sidebar.scss";

import { Box, CircularProgress, Collapse, IconButton, Typography } from "@mui/material";

import { IoMdClose } from "react-icons/io";
import { NavLink, useNavigate } from "react-router-dom";
import { navData as nav } from "./navBar/_nav";
import { MdExpandMore, MdOutlineExpandLess } from "react-icons/md";
import MenuBar from "../component/menuBar/menuBar";
import httpService from "../service/http.service";
import { api } from "../interfaces/api.interface";
import Levels from '../assets/levels.png';
import Subjects from '../assets/subjects.png';

const Sidebar = ({ items, classes, logo, type, menuToggleHandler, menuToggle }) => {
  const navigate = useNavigate();

  const [expanded, setExpanded] = React.useState("");
  const [selectedNav, setSelectedNav] = React.useState({});
  const [subjects, setSubjects] = useState([])
  const [isSubjectLoading, setIsSubjectLoading] = useState(false)


  const [nestedExpanded, setNestedExpanded] = React.useState("");
  const [nestedSelectedNav, setNestedSelectedNav] = React.useState({});
  const [nestedItems, setNestedItems] = useState([])
  const [isNestedSubjectLoading, setIsNestedSubjectLoading] = useState(false)

  const [secondnestedExpanded, setSecondNestedExpanded] = React.useState("");
  const [secondnestedSelectedNav, setSecondNestedSelectedNav] = React.useState({});

  const [navData, setNavData] = useState(nav);


  const handleExpandClick = (key) => {
    setSelectedNav(key)
    setExpanded((prev) => (prev === key.label ? "" : key.label));
    if (key.label == expanded) {
      setNestedExpanded("")
      setSecondNestedExpanded("")
      setSubjects([])
      setNestedItems([])
      setSelectedNav({})
      setNestedSelectedNav({})
      setSecondNestedSelectedNav({})
    }

  }
  const handleNestedExpandClick = (event, key) => {
    setNestedSelectedNav(key)
    setNestedExpanded((prev) => (prev === key.levelName ? "" : key.levelName));
    if (key.levelName == nestedExpanded) {
      setSecondNestedExpanded("")
      setNestedItems([])
      setNestedSelectedNav({})
      setSecondNestedSelectedNav({})
    }
  }

  const handleSecondNestedExpandClick = (event, key) => {
    setSecondNestedSelectedNav(key)
    setSecondNestedExpanded((prev) => (prev === key.id ? "" : key.id));
    menuToggleHandler()
  }

  useEffect(() => {
    const selectedId = nestedSelectedNav?.id
    if (selectedId) {
      getLevel(selectedId)
    }
  }, [nestedExpanded])

  const getLevel = (id) => {
    setIsNestedSubjectLoading(true)
    httpService.call(api.getSubjectsByLevelId(id)).then(res => {
      console.log(res)
      setIsNestedSubjectLoading(false)
      if (res.data.succeeded) {
        setNestedItems(res.data.data.$values)
      }
    }).catch(e => {
      console.log(e)
      setIsNestedSubjectLoading(false)
    })
  }

  useEffect(() => {
    const selectedId = selectedNav?.navData
    if (selectedId) {
      getProgram(selectedId)
    }
  }, [expanded])

  const getProgram = (id) => {
    setIsSubjectLoading(true)
    httpService.call(api.getLevelsByProgramId(id)).then(res => {
      console.log(res.data)
      setIsSubjectLoading(false)
      if (res.data.succeeded) {
        setSubjects(res.data.data.$values)
      }
    }).catch(e => {
      console.log(e)
      setIsSubjectLoading(false)
    })
  }


  return (
    <div className={`sidebar ${menuToggle ? 'show' : ''}`}>
      <Box sx={{ maxWidth: '450px', margin: '50px auto',}}>
        <IconButton sx={{ position: 'absolute', top: 10, left: 10, border:'1px solid #000'  }} aria-label="add" color="primary" onClick={menuToggleHandler}>
          <IoMdClose />
        </IconButton>
      </Box>
      {navData.map((navItem, index) => (
        <Box key={navItem.id} >
          <Box onClick={() => handleExpandClick(navItem)} className={`navMainText ${expanded === navItem.label ? 'selectedText' : ''}`}>
            <Typography variant="body2" component="span" className="navText">
              {navItem.label}
            </Typography>
            {navItem.navData && <>
              {
                expanded === navItem.label ? <MdOutlineExpandLess size={20} />
                  : <MdExpandMore size={20} />}
            </>}
          </Box>
          {selectedNav?.navData && <Collapse
            in={expanded === navItem.label}
            timeout="auto"
            unmountOnExit
          >
            {isSubjectLoading ?

              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress size={50} sx={{ margin: 5 }} />
              </Box> :
              <ul className="menuList">
                {
                  subjects.map((sub) => (
                    <li key={sub.id}>
                      <Box
                        onClick={(e) => {
                          handleNestedExpandClick(e, sub)
                        }}
                        className={`nestedItemNav ${nestedExpanded === sub.levelName ? 'nestedSelectText' : ''}`}
                      >
                        <Box className='nestedItemBox'>
                          <img src={Levels} />
                          <Typography variant="body2" component="span" className={`nestedNavText`}>
                            {sub.levelName}
                          </Typography>
                        </Box>
                        {
                          nestedExpanded === sub.levelName ? <MdOutlineExpandLess size={20} color="#fff" />
                            : <MdExpandMore size={20} />}
                      </Box>
                      {
                        nestedSelectedNav?.programId && <Collapse
                          in={nestedExpanded === sub.levelName}
                          timeout="auto"
                          unmountOnExit
                        >
                          {isNestedSubjectLoading &&

                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <CircularProgress size={50} sx={{ margin: 5 }} />
                            </Box>}
                          {
                            <ul className="nestedMenuList">
                              {
                                nestedItems.map((sub) => (
                                  <li key={sub.id}>
                                    <NavLink
                                      onClick={(e) => {
                                        handleSecondNestedExpandClick(e, sub)
                                      }}
                                      className={`secondNestedItemNav ${secondnestedExpanded === sub.id ? 'secondNestedSelectText' : ''}`}
                                      to={`${selectedNav.url}/${sub.id}`}
                                    >
                                      <Box className='secondNestedItemBox'>
                                        <img src={Subjects} />
                                        <Typography variant="body2" component="span" className={`secondNestedNavText`}>
                                          {sub.subjectName}
                                        </Typography>
                                      </Box>
                                    </NavLink>
                                  </li>
                                ))
                              }
                            </ul>
                          }
                        </Collapse>
                      }
                    </li>
                  ))
                }
              </ul>}
          </Collapse>}
        </Box>
      ))
      }
    </div>
  );
};

export default Sidebar;
