import axios from "axios";
import JSZip from "jszip";

export const pdfDownload = async (name, pdfUrl) => {
  try {
    const response = await fetch(
      pdfUrl
    );
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = url;
    link.download = `${name}.pdf`; // Set the filename for download
    document.body.appendChild(link);

    // Trigger the click event on the link to initiate download
    link.click();

    // Clean up: remove the temporary link element
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading PDF:', error);
  }
};


export const downloadPDFsAsZip = async (pdfUrls,setIsMultipleLoading) => {


  try {
    setIsMultipleLoading(true)
    // Create a new instance of JSZip
    const zip = new JSZip();

    // Fetch each PDF file and add it to the zip archive
    await Promise.all(
      pdfUrls.map(async (url, index) => {
        const response = await axios.get(url, { responseType: 'blob' });
        zip.file(`pdf${index + 1}.pdf`, response.data);
      })
    );

    // Generate the zip file asynchronously
    const zipBlob = await zip.generateAsync({ type: 'blob' });

    setIsMultipleLoading(false);

    // Create a temporary URL for the zip blob
    const url = window.URL.createObjectURL(zipBlob);

    // Create a link element and trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = 'pdf_files.zip';
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading PDFs:', error);
  }
};

const currentYear = new Date().getFullYear();
export const yearsList = Array.from({ length: 40 }, (_, index) => ({label: String(currentYear - index)}));

export const months = [
  {label:'January'}, {label:'February'}, {label:'March'}, {label:'April'}, {label:'May'}, {label:'June'},
  {label:'July'}, {label:'August'}, {label:'September'}, {label:'October'}, {label:'November'}, {label:'December'}
];

export const paperType = [
  {label:'QP'}, {label:'ER'}, {label:'GT'},
]
