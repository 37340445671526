import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import './filterBar.scss'
import DropDownField from "../autocomplete/autocomplete";
import SearchBar from "../searchBar/searchBar";
import { months, paperType, yearsList } from "../../utils/Utils";

const FilterBar = (props) => {
    const {
        setSearch,
        setYear,
        setMonth,
        setPaperType
    } = props
    return (
        <Box style={{ width: '90%', margin: '20px 0px' }}>
            <Grid container >
                <Grid item xs={12} md={6} lg={4}>
                    <Typography variant="h3" className="label">Search</Typography>
                    <Box className="searchField">
                        <SearchBar placeholder={'Search'} handleChange={setSearch} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <Typography variant="h3" className="label">Year</Typography>
                    <DropDownField placeholder={'Year'} list={yearsList} handleChange={setYear} />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <Typography variant="h3" className="label">Month</Typography>
                    <DropDownField placeholder={'Month'} list={months} handleChange={setMonth} />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Typography variant="h3" className="label">Resource Type</Typography>
                    <DropDownField placeholder={'Question Paper, Mark Scheme...'} list={paperType} handleChange={setPaperType} />
                </Grid>
            </Grid>
        </Box>
    )
};

export default FilterBar;
