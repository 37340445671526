import { Box, CircularProgress, Grid, Menu, MenuItem, Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import './menuBar.scss'
import Levels from '../../assets/levels.png';
import Subjects from '../../assets/subjects.png';
import SearchBar from "../searchBar/searchBar";
import httpService from "../../service/http.service";
import { api } from "../../interfaces/api.interface";
import { useNavigate } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";

const MenuBar = (props) => {
    const { anchorEl, setAnchorEl, open, program, menuToggleHandler } = props;

    const [programs, setPrograms] = useState([])
    const [isProgramLoading, setIsProgramLoading] = useState(false)
    const [selectedPrograms, setSelectedPrograms] = useState({})


    const [subjects, setSubjects] = useState([])
    const [isSubjectLoading, setIsSubjectLoading] = useState(false)

    const [search, setSearch] = useState('')

    const navigate = useNavigate();

    useEffect(() => {
        if (open && program.navData) {
            getProgram()
        }
    }, [open])

    const getProgram = () => {
        setIsProgramLoading(true)
        httpService.call(api.getLevelsByProgramId(program.navData)).then(res => {
            console.log(res)
            setIsProgramLoading(false)
            if (res.data.succeeded) {
                setPrograms(res.data.data.$values)
            }
        }).catch(e => {
            console.log(e)
            setIsProgramLoading(false)
        })
    }
    const getSubject = (id) => {
        setIsSubjectLoading(true)
        httpService.call(api.getSubjectsByLevelId(id)).then(res => {
            console.log(res)
            setIsSubjectLoading(false)
            if (res.data.succeeded) {
                setSubjects(res.data.data.$values)
            }
        }).catch(e => {
            console.log(e)
            setIsSubjectLoading(false)
        })
    }
    const selectProgram = (e) => {
        setSelectedPrograms(e)
        getSubject(e.id)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setPrograms([])
        setSelectedPrograms({})
        setSubjects([])
    }

    const selectSubject = (id) => {
        console.log(program.url)
        navigate(`${program.url}/${id}`)
        handleClose()
        if(menuToggleHandler){
            menuToggleHandler()
        }
    }

    return (
        <Menu
            id="basic-menu"
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            classes={{ list: "header-person-details-list" }}
            anchorEl={anchorEl}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            open={open}
            onClose={!isProgramLoading && !isSubjectLoading && handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
                onMouseLeave: handleClose 
            }}
            marginThreshold={50}
            PaperProps={{
                // width:'100%',
                // left:0,
                // right:0,
                elevation: 0,
                sx: {
                    overflow: "visible",
                    mt: 1.5,
                },
            }}
        >

            <Box className='boxContainer'>
                <Grid container >
                    <Grid item xs={4} style={{ margin: '20px 0' }}>
                        {!isProgramLoading ? <>
                            {
                                programs.map((e, i) => (
                                    <Box className='levelItems' onMouseOver={() => selectProgram(e)} >
                                        <img src={Levels} />
                                        <Typography variant="h3">{e.levelName}</Typography>
                                        <IoIosArrowRoundForward size={30} color="#3DCBB1" className="levelArrowIcon"/>
                                    </Box>
                                ))
                            }
                        </> :
                            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress size={50} sx={{ margin: 5 }} />
                                <Typography variant="body1">
                                    Loading....
                                </Typography>
                            </Box>}
                    </Grid>
                    <Grid item xs={8}>
                        <Box className='subjectContainer'>
                            {Object.keys(selectedPrograms).length > 0 ?
                                <>
                                    <Box className='subjectHeader'>
                                        <Typography variant="h2">{selectedPrograms.levelName}</Typography>
                                        <SearchBar placeholder={'Search'} handleChange={setSearch} />
                                    </Box>

                                    <Box className='subjectList'>
                                        {
                                            subjects.filter((e, i) => e.subjectName.toLowerCase().includes(search.toLowerCase())).map((e, i) => (
                                                <Box className='subjectItems' onClick={() => selectSubject(e.id)}>
                                                    <img src={Subjects} />
                                                    <Typography variant="h3">{e.subjectName}</Typography>
                                                </Box>
                                            ))
                                        }
                                    </Box>
                                </>
                                : <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <Typography variant="body1">Please Select Level First...</Typography>
                                </Box>}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Menu>
    )
};

export default memo(MenuBar);
