import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import "./home.scss";
import InputField from "../../component/inputField/inputField";
import CustomButton from "../../component/button";

import TutorImage from "../../assets/tutor.png";
import Helper2 from "../../assets/helper2.png";

const CoursesContainer = () => {
  return (
    <Box className="fourthContainer">
      <Box className="innerContainer">
        <Box className="formContainer">
          <Typography variant="body1">What do you want to learn?</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={3}>
              <InputField
                placeholder={"Find courses, skils, software, etc"}
                handleChange={() => {}}
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <InputField placeholder={"Categories"} handleChange={() => {}} />
            </Grid>
            <Grid item xs={6} lg={3}>
              <InputField placeholder={"Topic"} handleChange={() => {}} />
            </Grid>
            <Grid item xs={6} lg={3}>
              <CustomButton
                onClick={() => {}}
                text="Need a Tutor?"
                color="green"
                icon={TutorImage}
                className={"customButtonWidth"}
              />
            </Grid>
          </Grid>
        </Box>
        <Grid container>
          <Grid
            item
            xs={7}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="h2" className="heading">
              Courses
            </Typography>
            <Typography
              variant="h1"
              style={{ fontSize: "50px", color: "#fff" }}
            >
              How Can We Help?
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "end",
            }}
          >
            {/* <img src={Helper1} style={{ position: 'absolute', width: '20%', top: 0, left: 300 }} /> */}
            <img src={Helper2} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CoursesContainer;
