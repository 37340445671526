import React,{useEffect} from 'react';
import { Box, Button, Checkbox, Container, FormControlLabel, Grid, TextField, Typography, Paper, Chip } from '@mui/material';
import TutorRequestForm from '../../component/form/ReequestForm';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const EnglishTutorPage = () => {
 useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <Box sx={{backgroundColor: `white`}}>    <Box
    sx={{
      backgroundImage:`url(${require('../../assets/summerSchools.png')})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }}
  >
    <Box sx={{backgroundColor: `rgba(3, 73, 75, 0.95)`,
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      paddingTop:'50px',
 }}>
    <Container sx={{ mb: 4 }}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7} sm={7}>
            <Box sx={{ color: 'white' }}>
            
            <Typography className="headingText" variant="h3" sx={{ fontSize:{lg:24,sm:18,xs:16},marginBottom:1}}> Discover the Benefits of</Typography>
            <Typography  className="headingText" variant="h1" sx={{ fontSize:{lg:54,sm:48,xs:36},marginBottom:5}} >English Tutors</Typography>
            <Typography  className="headingText" variant="body1" color={'#fff'} sx={{ marginBottom:5  }}> <strong> English can be difficult to learn, but with the right tutor, you can </strong>. Our English teacher gives you personalised
                        attention and training to help you achieve. With us, you can reach your greatest potential!</Typography>

            <Grid container >
            <Grid item xs={12} sm={6} md={6} direction={'row'} alignItems='center'>
                  <Typography className="headingText" variant="h3"  sx={{ fontSize:{lg:20,sm:16,xs:12}, display: 'flex', mb: 2}}>
                  <img src={require('../../assets/check.png')} style={{width:'20px',height:'20px',marginRight:'10px',marginTop:5}}/>98.9% Satisfaction Rate
                    </Typography>
                    </Grid>
                  <Grid item xs={12} sm={6}  md={6} direction={'row'} alignItems='center'>
                  <Typography className="headingText" variant="h3" sx={{ fontSize:{lg:20,sm:16,xs:12}, mb: 2,display:'flex'}}>
                  <img src={require('../../assets/check.png')} style={{width:'20px',height:'20px',marginRight:'10px',marginTop:5}}/> First Lesson Satisfaction Guarantee
                    </Typography>
                    </Grid>
              
                    <Grid item xs={12} sm={6} md={6}>
              

                    <Typography className="headingText" variant="h3" sx={{ fontSize:{lg:20,sm:16,xs:12}, display: 'flex', mb: 2}}>
                    <img src={require('../../assets/check.png')} style={{width:'20px',height:'20px',marginRight:'10px',marginTop:5}}/> 4.8/5 Star Rating on Trustpilot
                    </Typography>
                  </Grid>
              
                    <Grid item xs={12} sm={6} md={6}>
           
                    <Typography className="headingText" variant="h3" sx={{ fontSize:{lg:20,sm:16,xs:12}, display: 'flex',mb: 2}}>
                    <img src={require('../../assets/check.png')} style={{width:'20px',height:'20px',marginRight:'10px',marginTop:5}}/> Trusted by 12,700+ Students
                    </Typography>
                  </Grid>
                </Grid>
                </Box>


          </Grid>
          <Grid item xs={12} md={5} sm={5} >
            <Paper sx={{ padding: '60px 80px', borderRadius: '16px', }} style={{display:'flex',flexDirection:'column'}}>
              <img src={require('../../assets/dm.png')} style={{width:'75px',height:'75px',marginRight:'10px',marginTop:5,alignSelf:'center',margin:12}}/>
              <Chip label={'Free'} variant="outlined" size='medium' style={{alignSelf:'center',color:'#03494b',borderColor:'#03494b',margin:12}}/>
            
              <Typography variant="h2" gutterBottom color={'#03494b'} >
                Get In Touch With <span style={{ color: '#00aaff' }}>English Expert</span>
              </Typography>
              <TextField
            fullWidth
            placeholder="Full Name"
            margin="normal"
            // variant="outlined"
            sx={{
                borderRadius:3,
              '& .MuiOutlinedInput-root': {
                borderRadius: '30px',
                padding: '10px',
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                },
                '& input': {
                  padding: '10px',
                },
              },
              '& .MuiInputBase-input::placeholder': {
                color: 'grey',
              },
            
            }}
          />              <TextField fullWidth placeholder='Enter your Phone Number'  margin="normal" variant="outlined"             sx={{
            borderRadius:3,
          '& .MuiOutlinedInput-root': {
            borderRadius: '30px',
            padding: '10px',
            '& fieldset': {
              borderColor: 'transparent',
            },
            '&:hover fieldset': {
              borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent',
            },
            '& input': {
              padding: '10px',
            },
          },
          '& .MuiInputBase-input::placeholder': {
            color: 'grey',
          },
        }}
/>
              <TextField fullWidth placeholder="Enter your E-mail" margin="normal" variant="outlined"             sx={{
                borderRadius:3,
              '& .MuiOutlinedInput-root': {
                borderRadius: '30px',
                padding: '10px',
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                },
                '& input': {
                  padding: '10px',
                },
              },
              '& .MuiInputBase-input::placeholder': {
                color: 'grey',
              },
            }}
/>
              <FormControlLabel
                control={<Checkbox sx={{color:'grey'}} />}
                label={<Typography variant='span' style={{fontSize:12}}>I agree to the <a href="#terms" style={{ color: '#00aaff' }}>Terms of Use</a> and <a href="#privacy" style={{ color: '#00aaff' }}>Privacy Policy</a></Typography>}
                sx={{ mt: 1 }}
              />
              <Button variant="contained" size="large"  sx={{ mt: 2,color:'white',alignSelf:'center',backgroundColor:'grey',borderRadius:3 }}>
                SUBMIT
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      </Box>
    </Box>
         <TutorRequestForm/>
          </Box>
  );
};

export default EnglishTutorPage;
