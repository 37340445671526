import React, { useState } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import LogoLarge from "../assets/logo.png";
import LoginImage from "../assets/login.png";
import TutorImage from "../assets/tutor.png";
import "./styles.scss";
import CustomButton from "../component/button";
import { IoMdPerson, IoMdLogIn } from "react-icons/io";
import Navbar from "./navBar/navBar";
import { AiOutlineMenu } from "react-icons/ai";
import Sidebar from "./Sidebar";
import Container from "../component/container/container";
import Cookies from "js-cookie";
import ProfileMenu from "../component/profileMenu/profileMenu";
import NeedATutorModal from "../component/modal/NeedATutuorModal";

const MainLayout = (props) => {
  const [menuToggle, setMenuToggle] = useState(false);

  const token = Cookies.get("token");
  const role = localStorage.getItem("role");
  console.log("Rlole==>", role);
  console.log("Token==>", token);
  // const user =  JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();

  const menuToggleHandler = () => {
    console.log("toggle");
    setMenuToggle((prev) => !prev);
  };

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  return (
    <Box>
      <Box className="mainBackground"></Box>
      <Container>
        <Box style={{ margin: "20px 0px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box className="toggleButton">
                <NavLink to={"home/home"}>
                  <img src={LogoLarge} />
                </NavLink>
                <IconButton
                  className="icon"
                  onClick={() => {
                    menuToggleHandler();
                  }}
                  size="small"
                >
                  <AiOutlineMenu color="#627a7e"></AiOutlineMenu>
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="buttonContainer">
                { token && role == "Student" ? (
                  <CustomButton
                    onClick={() => navigate("/studentDashboard")}
                    icon={TutorImage}
                    text="Dashboard"
                    color="green"
                    className={"customButtonWidth"}
                  />
                ) : (
                  <></>
                )}
                {token && role == "Teacher" ? (
                  <CustomButton
                    onClick={() => navigate("/teacherDashboard")}
                    text="Dashboard"
                    color="green"
                    className={"customButtonWidth"}
                    icon={TutorImage}
                  />
                ) : (
                  <CustomButton
                    // onClick={() => navigate("/tutor")}
                    onClick={handleOpen}
                    text="Need a Tutor?"
                    color="green"
                    icon={TutorImage}
                    className={"customButtonWidth"}
                  />
                )}
                {token ? (
                  <ProfileMenu />
                ) : (
                  <CustomButton
                    onClick={() => navigate("/login")}
                    text="Log In"
                    color="green"
                    icon={LoginImage}
                    className={"customButtonWidth"}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Navbar  token={token}/>
      <Sidebar menuToggle={menuToggle} menuToggleHandler={menuToggleHandler} />
      <Outlet />

      <Box className="footer">
        <Container>
          <Grid
            container
            spacing={1}
            style={{ justifyContent: "space-between", padding: "20px 10px" }}
          >
            <Grid item xs={12} md={6} lg={3}>
              <img src={LogoLarge} width={"120px"} />
              <Typography
                variant="h2"
                color={"#fff"}
                style={{ marginTop: "20px" }}
              >
                About Us
              </Typography>
              <Typography
                variant="body2"
                color={"#fff"}
                style={{ marginTop: "20px", maxWidth: "200px" }}
              >
                SER is your one-stop shop for conquering CAIE, International
                Baccalaureate (IB), EDExcel, and AQA exams. We're a passionate
                team on a mission to empower students worldwide with the tools
                and resources they need to thrive.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3} className="column">
              <Typography variant="h2" color={"#fff"}>
                A level
              </Typography>
              <Typography variant="body2" color={"#fff"} onClick={()=> navigate(`a-level-maths-tutors`)}>
                A level Math Tutor
              </Typography>
              <Typography variant="body2" color={"#fff"} onClick={()=> navigate(`a-level-biology-tutors`)}>
                A level Biology Tutor
              </Typography>
              <Typography variant="body2" color={"#fff"} onClick={()=> navigate(`a-level-physics-tutors`)}>
                A level Physics Tutor
              </Typography>
              <Typography variant="body2" color={"#fff"} onClick={()=> navigate(`a-level-chemistry-tutors`)}>
                A level Chemistry Tutor
              </Typography>
              {/* <Typography variant="body2" color={"#fff"} onClick={()=> navigate(`a-level-ma-tutors`)}>
                A level Accounting Tutor
              </Typography> */}
            </Grid>
            <Grid item xs={12} md={6} lg={3} className="column">
              <Typography variant="h2"  color={"#fff"}>
                Tutors
              </Typography>
              <Typography variant="body2" color={"#fff"} onClick={()=> navigate(`/math-tutor`)}>
                 Math Tutors
              </Typography>
              <Typography variant="body2" color={"#fff"} onClick={()=> navigate(`/biology-tutor`)}>
                 Biology Tutors
              </Typography>
              <Typography variant="body2" color={"#fff"} onClick={()=> navigate(`/physics-tutor`)}>
                 Physics Tutors
              </Typography>
              <Typography variant="body2" color={"#fff"} onClick={()=> navigate(`/english-tutor`)}>
                 English Tutors
              </Typography>
              <Typography variant="body2" color={"#fff"} onClick={()=> navigate(`/chemistry-tutor`)}>
                 Chemistry Tutors
              </Typography>
              <Typography variant="body2" color={"#fff"} onClick={()=> navigate(`/economics-tutor`)}>
                 Economics Tutors
              </Typography>
              <Typography variant="body2" color={"#fff"} onClick={()=> navigate(`/accounting-tutor`)}>
                 Accounting Tutors
              </Typography>
            </Grid>
            
            <Grid item xs={12} md={6} lg={3} className="column">
              <Typography variant="h2" color={"#fff"}>
                Useful Links
              </Typography>
              <Typography variant="body2" color={"#fff"}>
                TUTOR
              </Typography>
              <Typography variant="body2" color={"#fff"}>
                NOTES
              </Typography>
              <Typography variant="body2" color={"#fff"}>
                PAST PAPERS
              </Typography>
              <Typography variant="body2" color={"#fff"}>
                TEACHERS
              </Typography>
              <Typography variant="body2" color={"#fff"}>
                VIDEOS
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {modalOpen && <NeedATutorModal open={modalOpen} handleClose={handleClose} />}
    </Box>
  );
};

export default MainLayout;
