import React, { useState, useEffect } from "react";
import "./teacherProfile.scss";
import TeacherProfileCard from "../../component/profileCard/teacherProfileCard";
import SearchBar from "../../component/searchBar/searchBar";
import { Box, Typography, Grid, CircularProgress } from "@mui/material";
import { api } from "../../interfaces/api.interface";
import httpService from "../../service/http.service";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import LoginPromptModal from "../../component/modal/LoginPromptModal";


const RenderComponent = ({ data, onClick }) => {
  const { teacherName, yearOfExp, subjectSpec,image } = data;
  return (
    <Grid
      item
      xs={12}
      md={8}
      lg={6}
      style={{ margin: "12px 0px" }}
      className="teacher-centered-view"
    >
      <TeacherProfileCard
        onClick={onClick}
        name={teacherName}
        experience={yearOfExp}
        subject={subjectSpec}
        image={image}
      />
    </Grid>
  );
};

export default function TeacherProfile() {
  const token = Cookies.get("token");

  const [isLoading, setIsLoading] = useState(false);
  const [allTeachers, setAllTeachers] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const navigate = useNavigate();

  const onClickHandler = (teacher) => {
    navigate(`/teacherMaterial/${teacher["userId"]}`, { state: teacher });
    console.log(teacher["userId"]);
  };

  const getAllTeachers = () => {
    setIsLoading(true);
    httpService
      .call(api.getAllTeachers(), null)
      .then((res) => {
        if (res?.status == 200) {
          setAllTeachers(res.data["$values"]);
          console.log("data====>", res.data["$values"]);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  useEffect(() => {
    getAllTeachers();
  }, []);

  return (
    <Box className="teachers-main-container">
      <Box className="teachers-sub-container">
        <Typography
          variant="h1"
          className="profile-heading"
          style={{ textAlign: "center", marginBottom: 16 }}
        >
          Our Teachers
        </Typography>

        <Typography
          textAlign="center"
          variant="h6"
          className="profile-heading"
          style={{ margin: "0px 100px", width: "80%" }}
        >
          At SER PREP, our educators represent the pinnacle of IB excellence.
          Each of our instructors, whether a teacher or examiner, brings an
          average of 10 years of educational experience to the table. Our focus
          is specialized and in-depth, providing tutoring exclusively for the IB
          curriculum. Our tutors go beyond teaching; they understand the keys to
          academic success, many holding advanced degrees in their respective
          fields. Through their subject-specific guidance, students can enhance
          their learning skills, prepare effectively for tests, improve their
          grades, and embark on a path to academic excellence.
        </Typography>

        <Box className="profile-header">
          <Typography
            variant="h1"
            className="profile-heading"
            style={{ marginTop: 16, textAlign: "center", fontSize: 48 }}
          >
            Meet Our Teachers
          </Typography>

          <Grid container>
            <Grid item xs={6}></Grid>
          </Grid>
        </Box>

        <Box className="teacher-centered-view">
          <Box style={{ display: "flex", width: "100%" }}>
            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              {isLoading ? (
                <CircularProgress sx={{ color: '#03494b' }}/>
              ) : (
                allTeachers.map((teacher) => (
                  <RenderComponent
                    key={teacher.userId}
                    data={teacher}
                    onClick={() =>
                      token ? onClickHandler(teacher) : setModalOpen(true)
                    }
                  />
                ))
              )}
              {isModalOpen && (
                <LoginPromptModal
                  open={isModalOpen}
                  handleClose={handleCloseModal}
                />
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
