import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import DownloadImg from '../../assets/download.png'
import RectangleImg from '../../assets/Rectangle.png'
import pdfImg from '../../assets/pdf.png'
import './table.scss'
import CustomizedCheckbox from '../customCheckbox';
import { pdfDownload } from '../../utils/Utils';

const Table = (props) => {
    const { papers, handleSelectedPdf, multiPdfDownload, selectedPDF } = props

    const Header = () => (
        <Grid container className='header' style={{ background: '#E7E7E7' }} >
            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <img src={RectangleImg} className='rectangle' />
            </Grid>
            <Grid item xs={2} lg={1} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body2' >Doc Type</Typography>
            </Grid>
            <Grid item xs={5} lg={7} style={{ display: 'flex', alignItems: 'center',justifyContent:'center' }}>
                <Typography variant='body2'>Document Name</Typography>
            </Grid>
            <Grid item xs={3} lg={2} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body2'>Paper Type</Typography>
            </Grid>
            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }} onClick={multiPdfDownload}>
                <img src={DownloadImg} className='download' />
            </Grid>
        </Grid>
    )

    const CustomHeader = () => (
        <Grid container className='header' style={{ background: '#E7E7E7' }} >
            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <img src={RectangleImg} className='rectangle' />
            </Grid>
            <Grid item xs={2} lg={1} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body2' >Doc Type</Typography>
            </Grid>
            <Grid item xs={5} lg={7} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body2'>Document Name</Typography>
            </Grid>
            <Grid item xs={3} lg={2} style={{ display: 'flex', alignItems: 'center' }}>
                {/* <Typography variant='body2'>Paper Type</Typography> */}
            </Grid>
            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }} onClick={multiPdfDownload}>
                <img src={DownloadImg} className='download' />
            </Grid>
        </Grid>
    )

    const TableRow = (props) => {
        const { item } = props
        return (
            <>
                {item.questionPdf && <Grid container className='row' key={item.id}>
                    <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CustomizedCheckbox isSelected={selectedPDF.includes(item.questionPdf)} handleSelectedPdf={() => handleSelectedPdf(item.questionPdf)} />
                    </Grid>
                    <Grid item xs={2} lg={1} style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={pdfImg} className='pdfImg' />
                    </Grid>
                    <Grid item xs={5} lg={7} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography variant='h3'>{item.questionName}</Typography>
                        {/* <Typography variant='body1'>Uploaded 3 Days Ago</Typography> */}
                    </Grid>
                    <Grid item xs={3} lg={2} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='body1'>{item.paperType}</Typography>
                    </Grid>
                    <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }} onClick={pdfDownload.bind(this, item.questionName, item.questionPdf)}>
                        <img src={DownloadImg} className='download' />
                    </Grid>
                </Grid>
                }
            </>
        )
    }

    const NotesRow = (props) => {
        const { item } = props
        return (
            <>
                {item.notesPdf && <Grid container className='row' key={item.id}>
                    <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CustomizedCheckbox isSelected={selectedPDF.includes(item.notesPdf)} handleSelectedPdf={() => handleSelectedPdf(item.notesPdf)} />
                    </Grid>
                    <Grid item xs={2} lg={1} style={{ display: 'flex', justifyContent: 'start' }}>
                        <img src={pdfImg} className='pdfImg' />
                    </Grid>
                    <Grid item xs={5} lg={7} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography variant='h3'>{item.notesName}</Typography>
                        {/* <Typography variant='body1'>Uploaded 3 Days Ago</Typography> */}
                    </Grid>
                    <Grid item xs={3} lg={2} style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <Typography variant='body1'>{item.notesName}</Typography> */}
                    </Grid>
                    <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }} onClick={pdfDownload.bind(this, item.notesName, item.notesPdf)}>
                        <img src={DownloadImg} className='download' />
                    </Grid>
                </Grid>
                }
            </>
        )
    }

    const TableNestedRow = (props) => {
        const { item } = props
        return (
            <>
                {item.answerPdf && <Grid container className='row' key={item.id} >
                    <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CustomizedCheckbox isSelected={selectedPDF.includes(item.answerPdf)} handleSelectedPdf={() => handleSelectedPdf(item.answerPdf)} />
                    </Grid>
                    <Grid item xs={2} lg={1} style={{ display: 'flex', justifyContent: 'end' }}>
                        <img src={pdfImg} className='pdfImg' />
                    </Grid>
                    <Grid item xs={5} lg={7} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0 20px' }}>
                        <Typography variant='h3'>{item.answerName}</Typography>
                        {/* <Typography variant='body1'>Uploaded 3 Days Ago</Typography> */}
                    </Grid>
                    <Grid item xs={3} lg={2} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='body1'>{item.paperType}</Typography>
                    </Grid>
                    <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }} onClick={pdfDownload.bind(this, item.answerName, item.answerPdf)}>
                        <img src={DownloadImg} className='download' />
                    </Grid>
                </Grid>
                }
            </>
        )
    }
    return (
        <Grid container className="mainLayout">
            <Header /> 
            <Box style={{ background: '#F5F5F5', width: '100%' }}>
                {
                    papers.map((e, i) => (
                        <>
                            {e.questionPdf && <TableRow item={e} />}
                            {e.answerPdf && <TableNestedRow item={e} />}
                            {e.notesPdf && <NotesRow item={e} />}
                        </>
                    ))
                }
            </Box>
        </Grid>
    );
};

export default Table;