import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography,TextField,InputAdornment, textFieldClasses } from "@mui/material";
import './home.scss'
import OnlineCourses from '../../assets/onlineCourses.png'
import LearnSomething from '../../assets/learn.png'
import WhatNew from '../../assets/whatNew.png'
import ClientPortion from "../../assets/clientPortion.png";
import Play from "../../assets/play-circle.png";
import WebinarContainer from "./webinarContainer";
import CoursesContainer from "./coursesContaine";
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import { api } from "../../interfaces/api.interface";
import httpService from "../../service/http.service";
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from "react-router-dom";
import { setData } from "../../helpers/SubjectHelper";
import { setSubjects } from "../../redux/slices/data-slice";
import Marquee from "react-fast-marquee";
// Redux
import { useDispatch,useSelector } from "react-redux";

const Field = ()=> {  
    const placeHolders = ['Name','Email','Phone'];
    
    const [value,setValue] = useState('');
    const [data,setData] = useState([])
    const [index, setIndex] = useState(0);

    const switchTextField = () => {
        console.log(value,data);
        setIndex(index+1);
        setData([...data,value])
        setValue('');
        if(index ==3){
            setData([])
       }
    };

    return(
        <Box style={{width:'50%'}}>
        <Box className='row'>              
    <TextField
    className='item'
    placeholder={placeHolders[index]}
    variant='standard'
    // className="search"
    value={value}
    onChange={(e) => setValue(e.target.value)}
    // fullWidth
    InputProps={{
        disableUnderline: true
    }}
    style={{width:'80%',borderRadius:12,padding: '10px 32px',backgroundColor:'#fff',marginRight:24}}
/>
        <Button varant="contained" 
        className='item'
        style={{backgroundColor:'#03494B',color:'white',padding: '12px 32px',borderRadius:12}} onClick={()=>{}}>NEXT</Button>

</Box>
</Box>
)}

const Home = () => {
    
    const [searchText,setSearchText]= useState('');
    const [subjects,setSubjects] = useState([]);
    const [filterData,setFilterData] = useState([]);
    const [isloading,setIsLoading] = useState(false);
    const [banner,setBanner] =  useState(null);
    
    const {subjects:allSubjects} = useSelector(state => state?.data)
    console.log("Subjects",allSubjects)
    const navigate = useNavigate();

    const getAllSubjects = () => {
        setIsLoading(true)
        httpService.call(api.getSubjects()).then(res => {
            console.log(res?.data?.data?.$values.length)
            if (res.data.succeeded) {
                let data = res?.data?.data?.$values;
                setSubjects(data);
                setFilterData(data);
                // setData(data);
                localStorage.setItem('subjects',JSON.stringify(data))
                setIsLoading(false)
            }
        }).catch(e => {
            setIsLoading(false)
            console.log(e)
        })
    }

    const getHomePageBanners = () => {
        setIsLoading(true)
        httpService.call(api.getHomePageBanner()).then(res => { 
            console.log(res?.data?.data?.$values.length)
            if (res.data.succeeded) {
                let data = res?.data?.data?.$values[0]
                console.log("Homepage Banner",data);
                if(data){
                    setBanner(res?.data?.data?.$values[0])
                }
            }
        }).catch(e => {
            setIsLoading(false)
            console.log(e)
        })
    }
    // const FilterSubjects = (text)=> {
    //     setSearchText(text);
    //      let _filter = filterData?.filter((data)=> data?.subjectName?.toLowerCase().includes(text))
    //      if (text.length < 1){
    //         setSubjects(filterData)
    //      }else{
    //         setSubjects(_filter)
    //      };
    //      console.log(_filter);
    // }


    // const selectSubject = (id) => {
    //     navigate(`${program.url}/${id}`)
    // }

    useEffect(()=> {
        getAllSubjects()
        getHomePageBanners()
    },[])

    return (
        <Box style={{ width: '100%' }}>
            {banner && <Box className='bannerCon'style={{ width: '100%' }}>
              <Box className='banner'>
                        <img src={banner?.image} width={"100%"} height={"100%"} style={{objectFit:'cover'}} />
                    </Box>
              </Box>
              }
              <Marquee >
                <Typography  variant="body1" color={'#fff'} > What would you like to learn? <a href="https://www.w3schools.com">Visit W3Schools.com!</a></Typography>
                <pre>                                               </pre>
                <Typography  variant="body1" color={'#fff'} > Do you like to watch videos? <a href="https://youtube.com">Visit Youtube.com!</a></Typography>
                
                </Marquee>
            <Box className='topContainer'>
                <Box className='innerContainer' >
                    <Box className='textContainer'>
                        {/* <Typography variant="h2" className="heading">WHATS's NEW</Typography> */}
                        <Typography  className="headingText" variant="h1" sx={{ fontSize:{lg:48,sm:36,xs:30},margin:1}} >High Quality and Personalised 1-1 Online Tutoring</Typography>
                        <Typography className="headingText" variant="h3" sx={{ fontSize:{lg:24,sm:18,xs:16},margin:1}}> Maximise your potential with our interactive online tutoring lessons.</Typography>
                        <Typography  className="headingText" variant="body1" color={'#fff'} style={{ margin: 12 }}> What would you like to learn?</Typography>

                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            getOptionLabel={subject=> subject.subjectName}
                            options={subjects}
                            sx ={{ width: '100%' }}
                            onChange={(event, value,index) => navigate(`/subject/${value.id}`)}
                            renderInput={(params) => 
                            <TextField
                            variant='standard'
                            placeholder="Please enter the subject you wish to search for"
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                            }}
                            style={{width:'50%',borderRadius:12,padding:18,fontSize:48,backgroundColor:'#fff'}}
                        />
                    }
                />
                    </Box>
                </Box>
            </Box>





            <Box className='newContainer'>
                <Box className='innerContainer'>
                    <Grid container className="gridContainer">
                        <Grid item xs={6}  className="nestedContainer">
                            <Typography variant="h2" className="heading">WHATS's NEW</Typography>
                            <Typography variant="h1"  style={{ fontSize: '50px' }}>Learn Something New Everyday.</Typography>
                            <Typography variant="body1">Become professionals and ready to join the world</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <img src={LearnSomething} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            
            <CoursesContainer />

            <Box className='second-container'>
                <Box className='innerContainer'  >
                    <Box className='textContainer'>
                        <Typography className="headingText"variant="h2" sx={{ fontSize:{lg:48,sm:36,xs:30}}}>Your whole curriculum</Typography>
                        <Typography color={'#fff'} sx={{ fontSize:{lg:24,sm:18,xs:16}}}> At the click of a button</Typography>
                        <Typography variant="body1" color={'#fff'} style={{ margin: 12,width:"50%" }}> We provide specification-based lessons and flashcards to ensure you cover everything you need for that GCSE or A-Level exam.</Typography>
                    </Box>
                </Box>
            </Box>

            <Box className='secondContainer'>
                <Box className='innerContainer' style={{textAlign:'center'}}>
                <Typography variant="h2" style={{alignSelf:'center', fontSize: '36px',margin:32,color:'#03494B'}}>Why Choose SER? </Typography>

                    <Grid container className="gridContainer">
                        <Grid item xs={7} lg={6} >
                            <img src={WhatNew} />
                        </Grid>
                        <Grid item xs={5}  lg={6} className="nestedContainer"  style={{textAlign:'left'}}>                            
                        <Typography variant="body1" style={{color:'#03494B'}}>SPECIFICATION-BASED</Typography>
                            <Typography variant="h2" style={{color:'red', fontSize: '32px',marginTop:8,marginBottom:8,color:'#03494B' }}>Concept-driven lessons</Typography>
                            <Typography variant="body1" >Our content is written specifically for your exam board, covering every inch of your specification.</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>


            <Box className='last-container'>
                <Box className='innerContainer'  >
                    <Box className='textContainer' >
                        <Typography variant="h2" sx={{ fontSize:{lg:32,sm:28,xs:24}}} style={{color: '#fff', margin: 2,width:'80%' }}>Get in touch for SER Consultation</Typography>
                        <Typography variant="body1" color={'#fff'} style={{ margin: 30,width:"50%" }}> ------------------------------</Typography>
                    <Field/>
                    </Box>
                </Box>
            </Box>

            




            {/* <Box className='thirdContainer'>
                <Typography variant="h2" className="heading">SUMMER SCHOOLS</Typography>
                <Typography variant="h1" style={{ fontSize: '50px', color: '#fff', textAlign: 'center' }}>Video Tutorials <br /> And Weekly Classes</Typography>
                <Typography variant="body1" color={'#fff'}>ed ut perspiciatis unde omnis iste natus error sit.</Typography>
            </Box> */}
         
            {/* <WebinarContainer /> */}
            {/* <Box className='fifthContainer'>
                <img src={Play} />
            </Box> */}
            {/* <Box className='sixthContainer'>
                <Box className='innerContainer'>
                    <img src={ClientPortion} />
                </Box>
            </Box> */}
        </Box>
    )

}
export default Home;