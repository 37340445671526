export const apiURL = "https://serapi.serprep.com/api";
export const api = {
// export const apiURL = "https://serapi.designders.com/api";

  getPapers: (skip,take) => {
    return {
      url: `${apiURL}/Papers?skip=${skip}&take=${take}`,
      method: 'get',
    };
  },
  getPapersBySubjectId: id => {
    return {
      url: `${apiURL}/Papers/GetBySubjectId/${id}`,
      method: 'get',
    };
  },

  
  getPrograms: ()=> {
    return{
      url:`${apiURL}/Programs`,
      method:'get'
    }
  }
  ,
  getLevels: ()=> {
    return{
      url:`${apiURL}/Level`,
      method:'get'
    }
  },
  getSubjects: ()=> {
    return{
      url:`${apiURL}/Subjects`,
      method:'get'
    }
  },


  getLevelsByProgramId: id => {
    return {
      url: `${apiURL}/Level/GetByProgramId/${id}`,
      method: 'get',
    };
  },
  
  getSubjectsByLevelId: id => {
    return {
      url: `${apiURL}/Subjects/GetBylevelId/${id}`,
      method: 'get',
    };
  },
  
  signupUser: ()=> {
    return{
      url: `${apiURL}/identity/user`,
      method: 'post'
    }
  },
  loginUser: ()=> {
    return{
      url:`${apiURL}/identity/token`,
      method:'post'
    }
  },
  getRoles: ()=> {
    return{
      url:`${apiURL}/identity/user/UserRoles`,
      method:'get'
    }
  },
  getUserProfile: ()=> {
    return{
      url:`${apiURL}/identity/user/UserProfile`,
      method:'get'
    }
  },
  getAllNotes: ()=> {
    return{
      url:`${apiURL}/TeachersMaterial/GetAllNotes`,
      method:'get'
    }
  },
  getAllVideos: ()=> {
    return{
      url:`${apiURL}/TeachersMaterial/GetAllVideos`,
      method:'get'
    }
  },
  getAllTeachers: ()=> {
    return{
      url:`${apiURL}/TeachersMaterial/GetTeachersProfileForWebsite`,
      method:'get'
    }
  },

  getTeacherByID: (id)=> {
    return{
      url:`${apiURL}/TeachersMaterial/GetTeacherProfileById/${id}`,
      method:'get'
    }
  },
  getNotesByUserId: (userId)=> {
    return{
      url:`${apiURL}/TeachersMaterial/GetNotesByUserId?userId=${userId}`,
      method:'get'
    }
  },
  getVideosByUserId: (userId)=> {
    return{
      url:`${apiURL}/TeachersMaterial/GetVideosByUserId?userId=${userId}`,
      method:'get'
    }
  },
  studentCourse: ()=> {
    return{
      url:`${apiURL}/StudentsActivity/StudentActivity`,
      method:'post'
    }
  },
  isFirstLogIn: ()=> {
    return{
      url:`${apiURL}/StudentsActivity/CheckProgram`,
      method:'get'
    }
  },
  getStudentCourses: ()=> {
    return{
      url:`${apiURL}/StudentsActivity/GetStudentsSubject`,
      method:'get'
    }
  },
  
  getVideosForStudentBySubjectId: (SubjectId)=> {
    return{
      url:`${apiURL}/StudentsActivity/GetVideosForStudent/${SubjectId}`,
      method:'get'
    }
  },

  getNotesForStudentBySubjectId: (SubjectId)=> {
    return{
      url:`${apiURL}/StudentsActivity/GetNotesForStudent/${SubjectId}`,
      method:'get'
    }
  },

  uploadTeacherProfile: ()=> {
    return{
      url:`${apiURL}/TeachersMaterial/PostTeacherProfile`,
      method:'post'
    }
  },

  getTeacherProfile: ()=> {
    return{
      url:`${apiURL}/TeachersMaterial/GetTeacherProfile`,
      method:'get'
    }
  },

  uploadProfilePicture: ()=> {
    return{
      url:`${apiURL}/identity/account/UploadProfile-Picture`,
      method:'post'
    }
  },

  getHomePageBanner: ()=> {
    return{
      url:`${apiURL}/Banners/GetBanner?place=HomePage`,
      method:'get'
    }
  },

  getBanners: ()=> {
    return{
      url:`${apiURL}/Banners/GetBanner?place=website`,
      method:'get'
    }
  },
  changePassword: ()=>{
    return{
      url:`${apiURL}/identity/account/ChangePassword`,
      method:'put'
    }
  }
};

