import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import './autocomplete.scss'
import { styled } from '@mui/material/styles';


const StyledInputBase = styled(TextField)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    '& .MuiAutocomplete-inputRoot,':{
        padding: '0px !important'
    },
    '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline':{
        borderWidth: '0px !important'
    }
}));

const DropDownField = (props) => {
    const { placeholder, list, handleChange } = props
    return <Autocomplete
        className="boxLayout"
        disablePortal
        id="combo-box-demo"
        options={list}
        onChange={(_, data) => handleChange(data ? data.label : "")}
        renderInput={(params) => <StyledInputBase {...params} placeholder={placeholder} onChange={e=>console.log(e)} />}
    />
};

export default DropDownField;
